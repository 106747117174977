import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { sagas as employeeSagas, slice as employeeSlice } from './employees';
import {
  sagas as employeeInvitationSagas,
  slice as employeeInvitationSlice,
} from './employeeInvitations';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  employees: employeeSlice.reducer,
  employeeInvitations: employeeInvitationSlice.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

function* rootSaga() {
  try {
    yield all([...employeeSagas, ...employeeInvitationSagas].map(fork));
  } catch (e) {
    throw new Error(e);
  }
}

sagaMiddleware.run(rootSaga);
