import { all, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { User } from 'types';
import { omit } from 'lodash';
import Api, { CONSTANTS } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import employeeSlice from './slice';
import employeeInvitationSlice from '../employeeInvitations/slice';

interface EmployeeResponse {
  id: number;
  type: 'employee' | 'support_user';
  attributes: {
    firstName: string;
    lastName: string;
    phoneNumber: number;
    employeeRoleId: number;
    disabled: boolean;
    email: string;
  };
}

function* employeeFetch(action: PayloadAction<number>) {
  try {
    const employee: EmployeeResponse = yield Api.get(
      `${CONSTANTS.EMPLOYEES}/${action.payload}`
    ).then((result) => result.data.data);
    const normalizeEmployee: User = {
      ...omit(employee, 'attributes'),
      ...employee.attributes,
    };
    yield put(employeeSlice.actions.fetchEmployeeSuccess(normalizeEmployee));
  } catch (error) {
    yield put(employeeSlice.actions.fetchEmployeeFailed(error.message));
  }
}

function* employeesFetch() {
  try {
    const employees: Array<EmployeeResponse> = yield Api.get(
      CONSTANTS.EMPLOYEES
    ).then((result) => result.data.data);
    const normalizeEmployees: Array<User> = employees.map((employee) => ({
      ...omit(employee, 'attributes'),
      ...employee.attributes,
    }));
    yield put(employeeSlice.actions.fetchEmployeesSuccess(normalizeEmployees));
  } catch (error) {
    yield put(employeeSlice.actions.fetchEmployeesFailed(error.message));
  }
}

function* onEmployeeFetch(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(employeeSlice.actions.fetchEmployee, employeeFetch);
}

function* onEmployeesFetch(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(employeeSlice.actions.fetchEmployees, employeesFetch);
}

function* employeesAndPendingEmployeeInvitationsFetch() {
  yield all([
    put(employeeSlice.actions.fetchEmployees()),
    put(employeeInvitationSlice.actions.fetchPendingEmployeeInvitations()),
  ]);
}

function* onEmployeesAndInvitationsFetch(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    employeeSlice.actions.fetchEmployeesAndPendingEmployeeInvitations,
    employeesAndPendingEmployeeInvitationsFetch
  );
}

const sagas = [
  onEmployeeFetch,
  onEmployeesFetch,
  onEmployeesAndInvitationsFetch,
];
export default sagas;
