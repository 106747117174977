import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Card from 'components/atoms/Card';
import Header from 'components/atoms/Header';
import Logo from 'components/atoms/Logo';
import LoginForm from 'components/organism/LoginForm';
import Api, { CONSTANTS } from 'services/api';
import { LOGGED_IN } from 'services/routes';
import SEO from 'components/templates/SEO';

interface Response {
  status: string;
  token: string;
}

interface LoginLocationValue {
  from: unknown;
}

const Login: React.FC = () => {
  const firstRender = useRef(true);
  const location = useLocation<LoginLocationValue>();
  const [loading, setLoading] = useState<boolean>(false);
  const redirectToUrl = useMemo<string | null>(() => {
    const url = new URLSearchParams(location.search).get('redirect_to');

    if (!url) return null;

    if (!url?.includes('//')) return `//${url}`;

    return url;
  }, [location.search]);

  const handleSubmit = (user: string, pass: string) => {
    setLoading(true);
    Api.post<Response>(CONSTANTS.AUTHENTICATE, {
      email: user,
      password: pass,
    })
      .then(({ data }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('token', data.token);

        if (location.state?.from) {
          searchParams.set('from', JSON.stringify(location.state.from));
        }

        if (redirectToUrl) {
          window.location.href = `${redirectToUrl}?${searchParams.toString()}`;
          return;
        }

        window.location.href = `${LOGGED_IN}?${searchParams.toString()}`;
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [firstRender]);

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div
        className="lg:w-4/6 h-full items-center justify-center lg:container mx-6 lg:mx-auto mt-8 lg:mt-32"
        style={{ maxWidth: '880px' }}
      >
        <Logo
          className="text-center py-10 lg:py-0"
          size="text-5xl lg:text-7xl"
        />
        <div className="flex flex-col">
          <Header size="lg" className="lg:text-7xl lg:pb-4" bold>
            Login
          </Header>
          <Header size="sm" className="lg:text-3xl lg:pb-4" color="gray">
            Login med dit brugernavn eller din email
          </Header>
        </div>
        <Card className="my-4 border-t-8 border-primary hidden lg:block w-full">
          <LoginForm
            handleSubmit={handleSubmit}
            buttonSize="md"
            screenSize="lg"
          />
        </Card>
        <div className="block lg:hidden">
          <LoginForm
            handleSubmit={handleSubmit}
            buttonSize="full"
            screenSize="sm"
          />
        </div>
      </div>
      <SEO title="Login" />
    </>
  );
};

export default Login;
