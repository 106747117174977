import React from 'react';

interface Props {
  text: string;
  textColor?:
    | 'black'
    | 'white'
    | 'gray'
    | 'grayDarker'
    | 'grayDarkest'
    | 'primary';
  color: 'primary' | 'secondary' | 'red' | 'green' | 'gray';
  size?: 'sm' | 'lg';
  className?: string;
}

const ColoredLabel: React.FC<Props> = ({
  text,
  textColor = 'white',
  color,
  size = 'lg',
  className = '',
}) => {
  let classString = `text-center w-max rounded ${className} `;
  switch (color) {
    case 'primary':
      classString += 'bg-primary';
      break;
    case 'secondary':
      classString += 'bg-secondary';
      break;
    case 'red':
      classString += 'bg-danger';
      break;
    case 'green':
      classString += 'bg-success';
      break;
    case 'gray':
      classString += 'bg-grayDarkest';
      break;
  }
  return (
    <div className={`${classString} ${size === 'lg' ? 'px-4 py-2' : 'p-1'}`}>
      <span className={`text-${textColor} font-header text-${size}`}>
        {text}
      </span>
    </div>
  );
};
export default ColoredLabel;
