import { parseToken } from 'lib/parseToken';
import { AuthData } from 'types/DecodedAuthToken';

const AUTH_TOKEN = 'authToken';

export const getToken = (): string | null => localStorage.getItem(AUTH_TOKEN);
export const setToken = (token: string): void =>
  localStorage.setItem(AUTH_TOKEN, token);
export const removeToken = (): void => localStorage.removeItem(AUTH_TOKEN);

export const getParsedToken = (): AuthData | null => {
  const token = getToken();

  if (!token) return null;

  return parseToken(token);
};
