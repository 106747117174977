import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'services/auth';

import type { RouteProps } from 'react-router-dom';
import { LOGIN } from 'services/routes';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!auth) return null;
        if (!auth.initialized) return null;

        return auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
