import NavbarItem from 'components/atoms/NavbarItem';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EMPLOYEES, HOME, TOOLS, REPORTS, SETTINGS } from 'services/routes';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as ToolIcon } from 'assets/icons/Tools.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/Settings.svg';
import { ReactComponent as EmployeeIcon } from 'assets/icons/Employees.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/Collapse.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getToken } from 'services/auth/token';
import { RAILS_WEB_INTERFACE_AUTHENTICATION } from '../../../constants';

interface Props {
  extended: boolean;
  className?: string;
  handleExtended: (extended: boolean) => void;
}
const Navbar: React.FC<Props> = ({
  extended,
  className = '',
  handleExtended,
}) => {
  const [isExtended, setExtended] = useState(extended);
  const toggleExtended = useCallback(() => {
    setExtended(!isExtended);
  }, [isExtended]);

  useEffect(() => {
    handleExtended(isExtended);
  }, [handleExtended, isExtended]);

  useEffect(() => {
    setExtended(extended);
  }, [extended]);

  const authToken = useMemo<string | null>(() => {
    return getToken();
  }, []);

  return (
    <>
      <div
        className={`bg-white fixed drop-shadow-lg border-grayDarker border-r z-50 ${
          isExtended ? 'w-80' : 'w-16'
        } h-full overflow-hidden transition-all duration-300 linear ${className}`}
      >
        <div className="mx-0.5 mb-10">
          <Link to={{ pathname: HOME }}>
            <Logo width="180" height="105" viewBox="3 0 151 85" fill="none" />
            <h1
              className={`transition-opacity ease-rapid-out duration-300 ml-2.5 text-2xl absolute ${
                isExtended ? 'opacity-100' : 'opacity-0'
              }`}
            >
              App4Tool
            </h1>
            <h1
              className={`transition-opacity ease-rapid-out duration-300 linear ml-2.5 text-2xl absolute ${
                isExtended ? 'opacity-0' : 'opacity-100'
              }`}
            >
              A4T
            </h1>
          </Link>
        </div>
        {false && (
          <>
            <NavbarItem
              itemName="Dashboard"
              path={HOME}
              exact
              icon={<HomeIcon />}
            />
            <NavbarItem itemName="Værktøj" path={TOOLS} icon={<ToolIcon />} />{' '}
          </>
        )}
        <NavbarItem
          itemName="Medarbejdere"
          path={EMPLOYEES}
          icon={<EmployeeIcon />}
        />
        <div className="w-full border-grayDarker border-t border-b h-16">
          <div className="flex m-2 h-11 items-center rounded'text-black">
            <IconWrapper className="w-6 h-6 mr-4 ml-3 flex-shrink-0">
              <HomeIcon />
            </IconWrapper>
            <div className="overflow-hidden">
              <form method="POST" action={RAILS_WEB_INTERFACE_AUTHENTICATION}>
                <input
                  type="hidden"
                  name="employee[token]"
                  value={authToken || ''}
                />
                <button type="submit">Web interface</button>
              </form>
            </div>
          </div>
        </div>
        {false && (
          <>
            <div className="mx-0.5 mb-4 h-32 relative">
              <h3
                className={`transition-opacity duration-300 ml-2.5 text-2xl text-grayDarkest absolute bottom-1 ${
                  isExtended ? 'opacity-100' : 'opacity-0'
                }`}
              >
                Generelt
              </h3>
            </div>
            <NavbarItem
              itemName="Rapporter"
              path={REPORTS}
              icon={<ReportIcon />}
            />
            <NavbarItem
              itemName="Indstillinger"
              path={SETTINGS}
              icon={<SettingIcon />}
              isLast
            />
          </>
        )}
        <div className="h-full">
          <button
            className={`p-3 transition-all duration-300 absolute bottom-0 ${
              isExtended ? 'rotate-0' : 'rotate-180'
            }`}
            type="button"
            onClick={toggleExtended}
          >
            <CollapseIcon
              width="40"
              height="40"
              viewBox="0 0 35 35"
              fill="none"
            />
          </button>
        </div>
      </div>
    </>
  );
};

const IconWrapper = styled.div`
  & svg {
    height: 100%;
    width: 100%;
  }
`;

export default Navbar;
