import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import 'assets/styles/index.css';
import 'services/i18n';
import Router from 'components/templates/Router';
import Theme from 'services/theme';
import store from 'store/config';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
