import React, { useCallback, useState } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';

import Api, { CONSTANTS } from 'services/api';

import Card from 'components/atoms/Card';
import RenewPasswordForm from 'components/organism/RenewPasswordForm';
import FormBase from 'components/templates/FormBase';

import Confirmed from './Confirmed';

const RenewPasswordContent: React.FC<RouteComponentProps> = ({
  history: { replace },
  match: { path },
}) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const handleSubmit = useCallback(
    (newPassword: string) => {
      Api.patch<Response>(CONSTANTS.RENEW_PASSWORD, {
        token: token,
        newPassword: newPassword,
      })
        .then(() => {
          setSuccess(true);
          setTimeout(() => replace(`${path}/confirmed`), 2000);
        })
        .catch((error) => {
          setErrors(error.response.data.message);
          setSuccess(false);
        });
    },
    [path, replace, token]
  );

  return (
    <FormBase
      formTitle="Nyt kodeord"
      formSubtitle="Indtast dit nye kodeord"
      pageTitle="Nyt kodeord"
    >
      <Card className="my-4 border-t-8 border-primary hidden lg:block w-full">
        <RenewPasswordForm
          handleSubmit={handleSubmit}
          buttonSize="md"
          screenSize="lg"
          success={success}
          errors={errors}
        />
      </Card>
      <div className="block lg:hidden">
        <RenewPasswordForm
          handleSubmit={handleSubmit}
          buttonSize="full"
          screenSize="sm"
          success={success}
          errors={errors}
        />
      </div>
    </FormBase>
  );
};

const RenewPassword: React.FC<RouteComponentProps> = ({ match: { path } }) => {
  console.log({ path });
  return (
    <Switch>
      <Route path={path} exact component={RenewPasswordContent} />
      <Route path={`${path}/confirmed`} component={Confirmed} />
    </Switch>
  );
};

export default RenewPassword;
