import { createSelector } from '@reduxjs/toolkit';
import { RootState, EmployeeInvitationState } from 'store/types';
import entityAdators from './entityAdapter';

const {
  selectById: selectEmployeeInvitationById,
  selectAll: selectAllEmployeeInvitations,
  selectEntities: selectEmployeeInvitationEntities,
  selectIds: selectEmployeeInvitationIds,
  selectTotal: selectEmployeeInvitationTotal,
} = entityAdators.getSelectors();

export const selectSelf = (state: RootState): EmployeeInvitationState =>
  state.employeeInvitations;

export {
  selectEmployeeInvitationById,
  selectAllEmployeeInvitations,
  selectEmployeeInvitationEntities,
  selectEmployeeInvitationIds,
  selectEmployeeInvitationTotal,
};

export const selectAllPendingEmployeeInvitations = createSelector(
  selectSelf,
  (state: EmployeeInvitationState) =>
    selectAllEmployeeInvitations(state).filter(
      (employeeInvitation) => !employeeInvitation.employeeId
    )
);
