import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ViewBase from 'components/templates/ViewBase';
import Card from 'components/atoms/Card';
import TableRow from 'components/atoms/TableRow';
import { User, EmployeeInvitation } from 'types';
import InvitePopup from 'components/molecules/InvitePopup';
// import ColoredLabel from 'components/atoms/ColoredLabel';
import { useDispatch, useSelector } from 'react-redux';
import employeeSlice from 'store/employees/slice';
import { selectAllEmployees } from 'store/employees';
import { selectAllPendingEmployeeInvitations } from 'store/employeeInvitations';
import type { SorterTypes } from 'components/atoms/Sorter';
import type { RootState } from 'store/types';
import DeletePopup from 'components/molecules/DeletePopup';
import DeactivateEmployeePopup from 'components/molecules/DeactiveEmployeePopup';
import ResponsePopup from 'components/molecules/ResponsePopup';
import Api, { CONSTANTS } from 'services/api';

import EmployeeTableRow from './EmployeeTableRow';
import EmployeeInvitationTableRow from './EmployeeInvitationTableRow';
import TableHeaderRow from './TableHeaderRow';
import RowDelimiter from './RowDelimiter';

// const mapRoles = (roleID: number): ReactElement => {
//   switch (roleID) {
//     case 1:
//       return <ColoredLabel size="sm" color="gray" text="Medarbejder" />;
//     case 2:
//       return (
//         <ColoredLabel size="sm" color="green" text="Sikkerhedsansvarlig" />
//       );
//     case 3:
//       return <ColoredLabel size="sm" color="primary" text="Administrator" />;
//     default:
//       return <ColoredLabel size="sm" color="red" text="Ukendt" />;
//   }
// };

const Employees: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector<RootState, boolean>(
    (state) => state.employees.loading
  );
  const employeeInvitations = useSelector<RootState, Array<EmployeeInvitation>>(
    (state) => selectAllPendingEmployeeInvitations(state)
  );

  const employees = useSelector<RootState, Array<User>>((state) =>
    selectAllEmployees(state.employees)
  );

  const [data, setData] = useState<Array<User>>(employees);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [lastSorted, setLastSorted] = useState<string>('');
  const displayPopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const hidePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  const [popup, setPopup] = useState<ReactElement>(
    <InvitePopup onClick={hidePopup} />
  );

  const fetchEmployees = useCallback(() => {
    dispatch(
      employeeSlice.actions.fetchEmployeesAndPendingEmployeeInvitations()
    );
  }, [dispatch]);

  const sortData = useCallback(
    (key: keyof SorterTypes): void => {
      const sortKey = key as keyof User;
      const newData = [...data];
      newData.sort((a, b) => {
        if (sortKey === lastSorted) {
          // DESCENDING
          if ((a[sortKey] || 0) < (b[sortKey] || 0)) return 1;
          if ((a[sortKey] || 0) > (b[sortKey] || 0)) return -1;
        } else {
          // ASCENDING
          if ((a[sortKey] || 0) > (b[sortKey] || 0)) return 1;
          if ((a[sortKey] || 0) < (b[sortKey] || 0)) return -1;
        }
        return 0;
      });
      setLastSorted(lastSorted === sortKey ? '' : sortKey);
      setData(newData);
    },
    [data, lastSorted]
  );

  /* TEST ROLLER */

  const handleInvite = useCallback(() => {
    setPopup(<InvitePopup onClick={hidePopup} />);
    displayPopup();
  }, [displayPopup, hidePopup]);

  useEffect(() => {
    if (!loading) {
      setData(employees);
    }
  }, [employees, loading]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleDelete = useCallback(
    (id: number) => {
      setPopup(<DeletePopup onClick={hidePopup} employeeInvitationId={id} />);
      displayPopup();
    },
    [displayPopup, hidePopup]
  );

  const handleEmployeeDeactivate = useCallback(
    (employeeId: number) => {
      setPopup(
        <DeactivateEmployeePopup onClick={hidePopup} employeeId={employeeId} />
      );
      displayPopup();
    },
    [displayPopup, hidePopup]
  );

  const handleEmployeeActivate = useCallback(
    (employeeId: number) =>
      Api.post(`${CONSTANTS.EMPLOYEES}/${employeeId}/set_disable_status`, {
        disable: false,
      }).then(() => {
        dispatch(employeeSlice.actions.fetchEmployees());
      }),
    [dispatch]
  );

  const handleEmployeeRenewPassword = useCallback(
    (employeeMail: string) =>
      Api.post(CONSTANTS.REQUEST_PASSWORD_CHANGE, {
        email: employeeMail,
      })
        .then(() => {
          setPopup(
            <ResponsePopup
              headerText="Ny kode"
              successMessage="En forespørgsel om kodeskift er blevet sendt til medarbejderen"
              onClick={hidePopup}
            />
          );
          displayPopup();
        })
        .catch((error) => {
          setPopup(
            <ResponsePopup
              headerText="Fejl"
              errors={error.response.data.message}
              onClick={hidePopup}
            />
          );
          displayPopup();
        }),
    [displayPopup, hidePopup]
  );

  return (
    <ViewBase pageTitle="Medarbejdere" popup={popup} showPopup={showPopup}>
      <div className="px-10 h-full w-full text-center items-center">
        <Card
          padding={false}
          className="-top-8 relative bg-white z-20 overflow-y-hidden"
        >
          <table className="w-full">
            <thead>
              <TableHeaderRow sortData={sortData} />
            </thead>
            <tbody>
              {!isEmpty(employeeInvitations) && (
                <>
                  <RowDelimiter title="Invitationer" />
                  {employeeInvitations.map((employeeInvitation) => (
                    <EmployeeInvitationTableRow
                      handleDelete={handleDelete}
                      key={employeeInvitation.id}
                      employeeInvitation={employeeInvitation}
                      // mapRoles={mapRoles}
                    />
                  ))}
                  <RowDelimiter title="Medarbejdere" />
                </>
              )}
              {data.map((employee) => (
                <EmployeeTableRow
                  onDeactivate={handleEmployeeDeactivate}
                  onActivate={handleEmployeeActivate}
                  onRenewPassword={handleEmployeeRenewPassword}
                  key={employee.id}
                  employee={employee}
                  // mapRoles={mapRoles}
                />
              ))}
              <TableRow>
                <td colSpan={4}>
                  <button
                    type="button"
                    className="bg-primary px-12 py-2 rounded-lg font-body text-white"
                    onClick={handleInvite}
                  >
                    Invitér flere medarbejdere
                  </button>
                </td>
              </TableRow>
            </tbody>
          </table>
        </Card>
      </div>
    </ViewBase>
  );
};

export default Employees;
