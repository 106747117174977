import React, { useState, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ActionsButton from 'components/atoms/ActionsButton';
import TableRow from 'components/atoms/TableRow';
import { EmployeeInvitation } from 'types';
import { useDispatch } from 'react-redux';
import Api, { CONSTANTS } from 'services/api';
import employeeInvitationSlice from 'store/employeeInvitations/slice';
import Dropdown from 'components/molecules/Dropdown';
import { DropdownItem, DropdownItemType } from 'types/DropdownItem';

interface Props {
  handleDelete: (id: number) => void;
  employeeInvitation: EmployeeInvitation;
  // mapRoles: (roleId: number) => ReactElement;
}

const EmployeeInvitationTableRow: React.FC<Props> = ({
  handleDelete,
  employeeInvitation,
  // mapRoles,
}) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const handleResend = useCallback((): void => {
    Api.post(
      `${CONSTANTS.EMPLOYEE_INVITATIONS}/${employeeInvitation.id}/resend`
    )
      .then(() => {
        dispatch(
          employeeInvitationSlice.actions.fetchPendingEmployeeInvitations()
        );
        setShowDropdown(false);
      })
      .catch(() => {});
  }, [dispatch, employeeInvitation.id]);

  const dropdownItems = useMemo<DropdownItem[]>(
    () => [
      {
        type: DropdownItemType.BUTTON,
        icon: <FontAwesomeIcon icon={faPaperPlane} />,
        onClick: handleResend,
        text: 'Gensend',
      },
      {
        type: DropdownItemType.BUTTON,
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => handleDelete(employeeInvitation.id),
        color: 'danger',
        text: 'Slet',
      },
    ],
    [employeeInvitation.id, handleDelete, handleResend]
  );

  return (
    <TableRow border className="text-grayDarkest font-header text-left">
      {/* <td className="text-center w-[50px]">
    <input type="checkbox" />
  </td> */}
      <td className="flex justify-center p-1.5">
        {/* <UserPicture employee={employee} /> */}
      </td>
      {/* <td>{employeeInvitation.id}</td> */}
      <td>
        {[employeeInvitation.phoneNumber, employeeInvitation.email]
          .filter((t) => t)
          .join('/')}
      </td>
      {/* TODO map rolleid til roller */}
      {/* <td>{mapRoles(employeeInvitation.employeeRoleId)}</td> */}
      <td className="text-center">
        <ActionsButton
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        />
        <Dropdown
          collapsed={!showDropdown}
          className="absolute"
          items={dropdownItems}
        />
      </td>
    </TableRow>
  );
};

export default EmployeeInvitationTableRow;
