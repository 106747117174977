import React, { memo, useState } from 'react';
import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import { ScreenSize } from 'types';
import { validateEmail } from 'services/validations';

interface Props {
  buttonSize?: 'sm' | 'md' | 'lg' | 'full';
  handleSubmit: (username: string, password: string) => void;
  screenSize: ScreenSize;
}

const LoginForm: React.FC<Props> = ({
  buttonSize = 'md',
  handleSubmit,
  screenSize,
}) => {
  const [username, setUsername] = useState<string>('');
  const [pass, setPass] = useState<string>('');

  const getFormContents = () => {
    const inputClass =
      'text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none';
    return (
      <>
        <TextInput
          placeholder="Brugernavn"
          name="username"
          type="text"
          className={inputClass}
          labelSize="text-xs lg:text-sm"
          value={username}
          onChange={(value) => setUsername(value)}
          invalid={validateEmail(username)}
          screenSize={screenSize}
        />
        <TextInput
          name="password"
          type="password"
          placeholder="Password"
          className={inputClass}
          labelSize="text-xs lg:text-sm"
          value={pass}
          onChange={(value) => setPass(value)}
          // bottomElem={
          //   <a
          //     href="forgot_password"
          //     target="_blank"
          //     className="my-0 text-xs lg:text-xl text-gray"
          //   >
          //     Har du glemt dit kodeord?
          //   </a>
          // }
          screenSize={screenSize}
        />
      </>
    );
  };

  return (
    <Form
      handleSubmit={() => {
        handleSubmit(username, pass);
      }}
      submitButtonSize={buttonSize}
      submitButtonValue="Login"
    >
      {getFormContents()}
    </Form>
  );
};

export default memo(LoginForm);
