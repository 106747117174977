import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from 'types';

import employeeAdapter from './entityAdapter';

export const initialState = employeeAdapter.getInitialState({
  loading: false,
  error: '',
});

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    employeeAdded: employeeAdapter.addOne,
    fetchEmployee: (state, _action: PayloadAction<number>) => {
      return {
        ...state,
        loading: true,
      };
    },
    fetchEmployeeSuccess: (state, action: PayloadAction<User>) => {
      if (state.loading) {
        return employeeAdapter.setOne(
          { ...state, loading: false },
          action.payload
        );
      }

      return state;
    },
    fetchEmployeeFailed: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    fetchEmployees: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    fetchEmployeesSuccess: (state, action: PayloadAction<Array<User>>) => {
      if (state.loading) {
        // Or, call them as "mutating" helpers in a case reducer
        return employeeAdapter.setAll(
          { ...state, loading: false },
          action.payload
        );
      }

      return state;
    },
    fetchEmployeesFailed: (state, action: { payload: string }) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    fetchEmployeesAndPendingEmployeeInvitations: (state) => state,
  },
});

export default slice;
