import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import { memo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { validateEmail } from 'services/validations';
import { ScreenSize } from 'types';

interface Props {
  buttonSize?: 'sm' | 'md' | 'lg' | 'full';
  handleSubmit: (email?: string, phone?: string) => void;
  screenSize: ScreenSize;
  success: boolean;
  errors?: string;
}

const ForgotPasswordForm: React.FC<Props> = ({
  buttonSize = 'md',
  handleSubmit,
  screenSize,
  success,
  errors,
}) => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const getFormContents = () => {
    const inputClass =
      'text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none';
    return (
      <>
        <TextInput
          name="email"
          type="text"
          placeholder="Email"
          labelSize="text-xs lg:text-sm"
          value={email}
          invalid={validateEmail(email)}
          disabled={!!phone}
          className={inputClass}
          onChange={setEmail}
          screenSize={screenSize}
        />
        <span className="mb-4">ELLER</span>
        <PhoneInput
          placeholder="Telefonnummer"
          inputProps={{ name: 'phone', id: 'phoneInputweb' }}
          country="dk"
          value={phone}
          onChange={(phoneVal) => setPhone(phoneVal)}
          disabled={!!email}
          inputClass={inputClass}
          specialLabel="Telefonnummer"
        />
      </>
    );
  };

  return (
    <Form
      handleSubmit={() => {
        handleSubmit(email, phone);
      }}
      submitButtonSize={buttonSize}
      submitButtonValue="Ny kode"
    >
      <div>
        {getFormContents()}
        {errors && <span className="text-danger">{errors}</span>}
        {success && (
          <span className="text-green-500 pl-4">
            Vi har sendt dig et link til skift af dit kodeord.
          </span>
        )}
      </div>
    </Form>
  );
};

export default memo(ForgotPasswordForm);
