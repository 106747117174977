import React from 'react';
import ViewBase from 'components/templates/ViewBase';
import ColoredLabel from 'components/atoms/ColoredLabel';
import { Redirect } from 'react-router-dom';
import { EMPLOYEES } from 'services/routes';

const Home: React.FC = () => {
  // eslint-disable-next-line no-constant-condition
  if (true) return <Redirect to={{ pathname: EMPLOYEES }} />;

  return (
    <ViewBase pageTitle="Home">
      <div className="m-auto h-full w-1/2 text-center items-center">
        <h1>pathname er &quot;{window.location.pathname}&quot;</h1>
        <ColoredLabel size="lg" text="Stor label" color="green" />
        <ColoredLabel size="lg" text="Stor label" color="red" />
        <ColoredLabel size="lg" text="Stor label" color="primary" />
        <ColoredLabel size="lg" text="Stor label" color="gray" />
        <ColoredLabel size="lg" text="Stor label" color="secondary" />
        <ColoredLabel size="sm" text="Lille label" color="green" />
        <ColoredLabel size="sm" text="Lille label" color="red" />
        <ColoredLabel size="sm" text="Lille label" color="primary" />
        <ColoredLabel size="sm" text="Lille label" color="gray" />
        <ColoredLabel size="sm" text="Lille label" color="secondary" />
      </div>
    </ViewBase>
  );
};

export default Home;
