import React from 'react';
import { Link } from 'react-router-dom';

import SiteHeader from 'components/organism/SiteHeader';
import SEO from 'components/templates/SEO';

const NotFound: React.FC = () => {
  return (
    <>
      <SEO title="Fejl" />
      <SiteHeader title="Fejl" />
      <div className="w-full pointer-events-none">
        Vi kunne ikke finde den side du ledte efter.
        <Link to="/">Tilbage til start</Link>
      </div>
    </>
  );
};

export default NotFound;
