import { API_BASE } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const CONSTANTS = {
  BASE: API_BASE,
  AUTHENTICATE: '/authentication',
  SIGNUP: '/signup',
  AUTHENTICATE_VALIDATE: '/authentication/validate',
  EMPLOYEES: '/employees',
  EMPLOYEE_INVITATIONS: '/employee_invitations',
  PENDING_EMPLOYEE_INVITATIONS: '/employee_invitations?scope=pending',
  UPDATE_PASSWORD: '/user/update_password',
  RENEW_PASSWORD: '/user/renew_password',
  REQUEST_PASSWORD_CHANGE: '/user/request_password_change',
};
