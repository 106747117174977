import Sorter, { SorterTypes } from 'components/atoms/Sorter';
import TableHeader from 'components/atoms/TableHeader';
import TableRow from 'components/atoms/TableRow';

interface Props {
  sortData: (key: keyof SorterTypes) => void;
}

const TableHeaderRow: React.FC<Props> = ({ sortData }) => (
  <TableRow>
    {/* <th>
      <input type="checkbox" />
    </th> */}
    <th>
      <TableHeader text="Billede" />
    </th>
    {/* <th>
      <Sorter handleClick={sortData} sortKey="id" text="ID" />
    </th> */}
    <th>
      <Sorter handleClick={sortData} sortKey="firstName" text="Navn" />
    </th>
    {/* <th>
      <Sorter handleClick={sortData} sortKey="employeeRoleId" text="Roller" />
    </th> */}
    <th className="pointer-events-none">
      <TableHeader text="Etc." />
    </th>
  </TableRow>
);

export default TableHeaderRow;
