import React from 'react';
import Header from 'components/atoms/Header';
import Logo from 'components/atoms/Logo';
import SEO from 'components/templates/SEO';

interface Props {
  formTitle: string;
  formSubtitle: string;
  pageTitle: string;
}
const FormBase: React.FC<Props> = ({
  formTitle,
  formSubtitle,
  pageTitle,
  children,
}) => {
  return (
    <>
      <div
        className="lg:w-4/6 h-full items-center justify-center lg:container mx-6 lg:mx-auto mt-8 lg:mt-32"
        style={{ maxWidth: '880px' }}
      >
        <Logo
          className="text-center py-10 lg:py-0"
          size="text-5xl lg:text-7xl"
        />
        <div className="flex flex-col">
          <Header size="lg" className="lg:text-7xl lg:pg-4" bold>
            {formTitle}
          </Header>
          <Header size="sm" className="lg:text-3xl lg:pb-4" color="gray">
            {formSubtitle}
          </Header>
        </div>
        {children}
      </div>
      <SEO title={pageTitle} />
    </>
  );
};

export default FormBase;
