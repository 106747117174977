import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getParsedToken } from 'services/auth/token';
import { selectEmployeeById } from 'store/employees';
import { RootState } from 'store/types';
import employeeSlice from 'store/employees/slice';
import ViewBase from 'components/templates/ViewBase';
import Card from 'components/atoms/Card';
import EmployeeInfo from 'components/organism/EmployeeInfo';
import { User } from 'types';

type ProfileParams = {
  id?: string;
};

const EmployeeView: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<ProfileParams>();
  let employeeId: number | null = null;

  if (id) {
    employeeId = parseInt(id, 10);
  }

  const viewedEmployee: User | null | undefined = useSelector(
    (state: RootState) => {
      if (employeeId) return selectEmployeeById(state.employees, employeeId);
      return null;
    }
  );

  useEffect(() => {
    if (!viewedEmployee) {
      const authData = getParsedToken();
      if (authData) {
        dispatch(employeeSlice.actions.fetchEmployees());
      }
    }
  }, [viewedEmployee, dispatch]);

  return (
    <ViewBase
      pageTitle={
        viewedEmployee
          ? `${viewedEmployee.firstName} ${viewedEmployee.lastName}`
          : 'Medarbejders profil'
      }
    >
      <div className="px-10 h-full w-full">
        {viewedEmployee && (
          <Card
            padding={false}
            className="-top-8 relative bg-white z-20 overflow-y-hidden"
          >
            <EmployeeInfo employee={viewedEmployee} />
          </Card>
        )}
      </div>
    </ViewBase>
  );
};

export default EmployeeView;
