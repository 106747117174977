import React, { useState } from 'react';
import { navbarContext } from 'services/navbar';
// import { NavbarExtended } from 'types';

// const data = {
//   extended: true,
//   setExtended: (value: boolean) => {
//     data.extended = value;

//     return Promise.resolve();
//   },
// };

const NavbarExtendedContext: React.FC = ({ children }) => {
  const [extended, setExtended] = useState(true);

  return (
    <navbarContext.Provider value={[extended, setExtended]}>
      {children}
    </navbarContext.Provider>
  );
};

export default NavbarExtendedContext;
