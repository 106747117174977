import axios, { AxiosError, AxiosResponse } from 'axios';
import { CONSTANTS } from './url-constant';

export const AUTH_HEADER_KEY = 'Authorization';
export { CONSTANTS };

const axiosInstance = axios.create({
  baseURL: CONSTANTS.BASE,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Key-Inflection': 'camel',
  },
});

export const setHeader = (key: string, value: string): void => {
  axiosInstance.defaults.headers.common[key] = value;
};

export const setBearerToken = (token: string): void =>
  setHeader(AUTH_HEADER_KEY, `Bearer ${token}`);

export const removeHeader = (key: string): void => {
  delete axiosInstance.defaults.headers.common[key];
};

export const removeAuthHeader = (): void => removeHeader(AUTH_HEADER_KEY);

/**
 * Intercept all 401 errors and redirect to
 * "/login"
 */
axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => res,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
