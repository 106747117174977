import UserPicture from 'components/atoms/UserPicture';
import ProfileForm from 'components/organism/ProfileForm';
import { useState } from 'react';
import Api, { CONSTANTS } from 'services/api';
import { User } from 'types';

interface Props {
  employee: User;
}

const EmployeeInfo: React.FC<Props> = ({ employee }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const handleEdit = (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) => {
    if (editing && employee) {
      Api.patch<Response>(`${CONSTANTS.EMPLOYEES}/${employee.id}`, {
        employee: {
          firstName,
          lastName,
          email,
          phoneNumber,
        },
      })
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setErrors(error.response.data.message);
        });
    }

    setEditing(!editing);
  };
  return (
    <div className="flex flex-row">
      <UserPicture className="m-8" employee={employee} size="xl" />
      <ProfileForm
        handleEdit={handleEdit}
        editing={editing}
        employee={employee}
        errors={errors}
        success={success}
      />
    </div>
  );
};

export default EmployeeInfo;
