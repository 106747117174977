import { useContext } from 'react';
import type { NavbarExtended } from 'types';

import navbarExtendedContext from './navbarExtendedContext';

const useNavbarExtendedContext = (): NavbarExtended => {
  return useContext(navbarExtendedContext);
};

export default useNavbarExtendedContext;
