import React, { memo } from 'react';

interface Props {
  className?: string;
  size?: string;
}
const Logo: React.FC<Props> = ({ className = '', size = 'text-7xl' }) => {
  return (
    <div className={`p-2 m-2 ${className}`}>
      <span className={`text-primary ${size} font-header font-black`}>
        APP4TOOL
      </span>
    </div>
  );
};

export default memo(Logo);
