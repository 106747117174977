import TableRow from 'components/atoms/TableRow';
import styled from 'styled-components';

interface Props {
  title: string;
}

const RowDelimiter: React.FC<Props> = ({ title }) => (
  <TableRow border className="text-grayDarkest font-header text-left">
    <td colSpan={4} className="p-1.5">
      <TextWrapper className="ml-2">{title}</TextWrapper>
    </td>
  </TableRow>
);

const TextWrapper = styled.div`
  font-size: 18pt;
`;

export default RowDelimiter;
