import Card from 'components/atoms/Card';
import Header from 'components/atoms/Header';
import Logo from 'components/atoms/Logo';
import SignupForm from 'components/organism/SignupForm';
// import SEO from 'components/templates/SEO';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Api, { CONSTANTS } from 'services/api';

interface Response {
  status: string;
  token: string;
}
const Signup: React.FC = () => {
  const [signedUp, setSignedUp] = useState<boolean>(false);

  const handleSubmit = (
    signupToken: string,
    firstName: string,
    lastName: string,
    pass: string,
    email: string,
    phone: string
  ) => {
    Api.post<Response>(CONSTANTS.SIGNUP, {
      token: signupToken,
      employee: {
        firstName,
        lastName,
        password: pass,
        email,
        phoneNumber: phone,
      },
    })
      .then(({ data }) => {
        if (data) setSignedUp(true);
      })
      .catch(() => {});
  };
  return (
    <>
      {/* <SEO title="Signup" /> */}
      {signedUp && <Redirect to="/signedup" />}
      <div
        className="w-full lg:w-4/6 max-w-xs h-full items-center justify-center lg:container mx-auto mt-32"
        style={{ maxWidth: '880px' }}
      >
        <Logo
          className="text-center w-full text-9xl py-36 lg:py-0"
          size="text-9xl lg:text-7xl"
        />
        <div className="flex flex-col">
          <Header size="2xl">Opret en bruger</Header>
          <Header size="MD">
            Opret en bruger med et selvvalgt bruger og adgangskode
          </Header>
        </div>
        <Card className="my-4 border-t-8 border-primary hidden lg:block w-full">
          <SignupForm handleSubmit={handleSubmit} buttonSize="md" id="web" />
        </Card>
        <div className="block lg:hidden">
          <SignupForm
            handleSubmit={handleSubmit}
            buttonSize="full"
            id="mobile"
          />
        </div>
      </div>
    </>
  );
};

export default Signup;
