import React from 'react';
import { ReactComponent as ActionsIcon } from 'assets/icons/Actions.svg';

interface Props {
  onClick: () => void;
}

const ActionsButton: React.FC<Props> = ({ onClick }) => (
  <button type="button" onClick={onClick}>
    <ActionsIcon width="20" height="20" viewBox="0 0 20 20" fill="none" />
  </button>
);

export default ActionsButton;
