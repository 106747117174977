import React, { ReactNode, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { DropdownColor } from 'types/DropdownItem';

interface Props {
  icon?: ReactNode;
  color?: DropdownColor;
}

interface ButtonProps extends Props {
  onClick: () => void;
}

const defaultClassName = 'flex items-center py-2';

const DropdownContent: React.FC<Props> = ({
  icon,
  color = 'primary',
  children,
}) => {
  const textColor = useMemo<string>(() => {
    switch (color) {
      case 'primary':
        return 'text-primary';
      case 'secondary':
        return 'text-gray';
      case 'danger':
        return 'text-red-500';
      default:
        return '';
    }
  }, [color]);

  return (
    <>
      <IconWrapper className={`w-5 ${textColor}`}>{icon}</IconWrapper>
      <div className={`pl-2 pr-2 ${textColor}`}>{children}</div>
    </>
  );
};

const IconWrapper = styled.div`
  & > svg {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const DropdownItemLink: React.FC<Props & LinkProps> = ({
  icon,
  color = 'primary',
  children,
  to,
  ...rest
}) => (
  <Link to={to} className={defaultClassName} {...rest}>
    <DropdownContent icon={icon} color={color}>
      {children}
    </DropdownContent>
  </Link>
);

export const DropdownItemButton: React.FC<ButtonProps> = ({
  icon,
  color = 'primary',
  children,
  onClick,
  ...rest
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={defaultClassName}
      {...rest}
    >
      <DropdownContent icon={icon} color={color}>
        {children}
      </DropdownContent>
    </button>
  );
};
