import Popup from 'components/atoms/Popup';
import React from 'react';

interface Props {
  headerText: string;
  successMessage?: string;
  errors?: string;
  onClick: () => void;
}
const ResponsePopup: React.FC<Props> = ({
  headerText,
  successMessage,
  errors,
  onClick,
}) => {
  return (
    <Popup headerText={headerText} headerSize="lg" onClick={onClick}>
      {successMessage && (
        <span className="text-green-500">{successMessage}</span>
      )}
      {errors && <span className="text-red-500">{errors}</span>}
      {!successMessage && !errors && (
        <span className="text-red-500">Noget gik galt</span>
      )}
    </Popup>
  );
};

export default ResponsePopup;
