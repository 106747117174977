import React from 'react';
import { Employee } from 'types';
import { Product } from 'types/Product';
import { ReactComponent as SortIcon } from 'assets/icons/Sort.svg';
import TableHeader from '../TableHeader';

export type SorterTypes = Employee & Product;

interface Props {
  sortKey: keyof SorterTypes;
  handleClick: (sortKey: keyof SorterTypes) => void;
  text: string;
}

const Sorter: React.FC<Props> = ({ sortKey, handleClick, text }) => {
  return (
    <div className="flex flex-row justify-between">
      <TableHeader text={text} />
      <button
        type="button"
        className="mr-4"
        onClick={() => handleClick(sortKey)}
      >
        <SortIcon width="20" height="19" viewBox="0 0 20 19" fill="none" />
      </button>
    </div>
  );
};

export default Sorter;
