import Card from 'components/atoms/Card';
import ForgotPasswordForm from 'components/organism/ForgotPasswordForm';
import FormBase from 'components/templates/FormBase';
import React, { useCallback, useState } from 'react';
import Api, { CONSTANTS } from 'services/api';

const ForgotPassword: React.FC = () => {
  //const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');

  const handleSubmit = useCallback((email?: string, phone?: string) => {
    const deliveryMethod = email ? 'email' : 'phone';
    // setLoading(true);
    Api.post<Response>(CONSTANTS.REQUEST_PASSWORD_CHANGE, {
      email: email,
      phoneNumber: `+${phone}`,
      forceDeliveryMethod: deliveryMethod,
    })
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => setErrors(error.response.data.message));
  }, []);

  // if (loading) {
  //   return <div>Loading</div>;
  // }

  return (
    <FormBase
      formTitle="Glemt kodeord"
      formSubtitle="Indtast din email"
      pageTitle="Glemt kodeord"
    >
      <Card className="my-4 border-t-8 border-primary hidden lg:block w-full">
        <ForgotPasswordForm
          handleSubmit={handleSubmit}
          buttonSize="md"
          screenSize="lg"
          success={success}
          errors={errors}
        />
      </Card>
      <div className="block lg:hidden">
        <ForgotPasswordForm
          handleSubmit={handleSubmit}
          buttonSize="full"
          screenSize="sm"
          success={success}
          errors={errors}
        />
      </div>
    </FormBase>
  );
};

export default ForgotPassword;
