import React from 'react';
import DropdownWrapper from 'components/atoms/DropdownWrapper';
import {
  DropdownItemButton,
  DropdownItemLink,
} from 'components/atoms/DropdownItem';
import {
  DropdownButtonItem,
  DropdownItem,
  DropdownItemType,
  DropdownLinkItem,
} from 'types/DropdownItem';

interface Props {
  collapsed: boolean;
  className?: string;
  items: DropdownItem[];
}

const renderButtonItem = (item: DropdownButtonItem) => (
  <DropdownItemButton
    icon={item.icon}
    onClick={item.onClick}
    color={item.color}
    key={item.text}
  >
    {item.text}
  </DropdownItemButton>
);

const renderLinkItem = (item: DropdownLinkItem) => (
  <DropdownItemLink
    icon={item.icon}
    to={item.to}
    color={item.color}
    key={item.text}
  >
    {item.text}
  </DropdownItemLink>
);

const renderItem = (item: DropdownItem) => {
  if (item.type === DropdownItemType.BUTTON)
    return renderButtonItem(item as DropdownButtonItem);

  return renderLinkItem(item as DropdownLinkItem);
};

const Dropdown: React.FC<Props> = ({ className = '', collapsed, items }) => {
  return (
    <DropdownWrapper collapsed={collapsed} className={className}>
      {items.map((item) => renderItem(item))}
    </DropdownWrapper>
  );
};

export default Dropdown;
