import React from 'react';
import { User } from 'types';

interface Props {
  employee: User;
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const UserPicture: React.FC<Props> = ({
  employee: { firstName, lastName },
  className = '',
  size = 'sm',
}) => {
  let numSize = 0;
  let fontSize = '';
  switch (size) {
    case 'sm':
      numSize = 50;
      fontSize = 'text-2xl';
      break;
    case 'md':
      numSize = 100;
      fontSize = 'text-4xl';
      break;
    case 'lg':
      numSize = 200;
      fontSize = 'text-6xl';
      break;
    case 'xl':
      numSize = 400;
      fontSize = 'text-9xl';
  }

  return (
    <svg
      className={`${className}`}
      width={numSize}
      height={numSize}
      viewBox={`0 0 ${numSize} ${numSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={numSize / 2}
        cy={numSize / 2}
        r={numSize / 2 - 1}
        fill="#F1F3F9"
        stroke="#F59E0B"
        strokeWidth="2"
      />
      <text
        className={`font-header ${fontSize}`}
        x="50%"
        y="50%"
        dy="-.05em"
        width="100"
        height="100%"
        alignmentBaseline="central"
        textAnchor="middle"
        fill="black"
      >
        {(firstName || '-').charAt(0) + (lastName || '-').charAt(0)}
      </text>
    </svg>
  );
};

export default UserPicture;
