import React from 'react';

interface Props {
  border?: boolean;
  className?: string;
  handleClick?: () => void;
}

const TableRow: React.FC<Props> = ({
  border = false,
  children,
  className = '',
  handleClick,
}) => (
  <tr
    onClick={handleClick}
    className={`h-16 ${
      border ? 'border-t border-b border-grayDarker box-border' : ''
    } ${className}`}
  >
    {children}
  </tr>
);

export default TableRow;
