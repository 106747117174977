import React from 'react';
import Card from '../Card';
import Header from '../Header';

interface Props {
  headerText: string;
  onClick: () => void;
  headerSize?: 'sm' | 'md' | 'Md' | 'MD' | 'lg' | 'xl' | '2xl' | '3xl';
}

const Popup: React.FC<Props> = ({
  headerText,
  headerSize = 'xl',
  children,
  onClick,
}) => {
  return (
    <Card className="w-160 -m-80 bg-white">
      <div className="w-full flex justify-between items-baseline flex-row">
        <Header size={headerSize}>{headerText}</Header>
        <button
          type="button"
          className="text-grayDarkest text-6xl"
          onClick={onClick}
        >
          x
        </button>
      </div>
      {children}
    </Card>
  );
};

export default Popup;
