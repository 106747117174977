import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from 'components/views/Home';
import Tools from 'components/views/Tools';
import Tool from 'components/views/Tool';
import NotFound from 'components/views/NotFound';
import Login from 'components/views/Login';
import LoggedIn from 'components/views/LoggedIn';
import Signup from 'components/views/Signup';
import Employees from 'components/views/Employees';
import ForgotPassword from 'components/views/ForgotPassword';
import RenewPassword from 'components/views/RenewPassword';
import {
  HOME,
  TOOLS,
  TOOL,
  LOGIN,
  LOGGED_IN,
  ERROR,
  SIGNUP,
  SIGNEDUP,
  EMPLOYEES,
  PROFILE,
  EMPLOYEE,
  FORGOT_PASSWORD,
  RENEW_PASSWORD,
  // SETTINGS,
  // REPORTS,
} from 'services/routes';
import ProvideAuth from 'providers/auth/ProvideAuth';
import NavbarExtendedContext from 'providers/navbar/NavbarExtendedContext';
import PrivateRoute from 'components/atoms/PrivateRoute';
import Api, { setBearerToken, removeAuthHeader, CONSTANTS } from 'services/api';
import SignedUp from 'components/views/SignedUp';
import { getToken, removeToken, setToken } from 'services/auth/token';
import Profile from 'components/views/Profile';
import Employee from 'components/views/Employee/Employee';

const authObject = {
  initialize: async (): Promise<string | null> => {
    const token = getToken();

    if (!token) return null;
    setBearerToken(token);

    try {
      await Api.post(CONSTANTS.AUTHENTICATE_VALIDATE);
    } catch (e) {
      authObject.signOut();
      return null;
    }

    return token;
  },
  loggedIn: (token: string): Promise<void> => {
    if (token !== getToken()) {
      setToken(token);
      setBearerToken(token);
    }

    return Promise.resolve();
  },
  signOut: (): Promise<void> => {
    removeToken();
    removeAuthHeader();

    return Promise.resolve();
  },
};

const Router: React.FC = () => {
  return (
    <ProvideAuth authObject={authObject}>
      <NavbarExtendedContext>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path={HOME} exact>
              <Home />
            </PrivateRoute>
            <PrivateRoute path={TOOLS} exact>
              <Tools />
            </PrivateRoute>
            <PrivateRoute path={TOOL} exact>
              <Tool />
            </PrivateRoute>
            <PrivateRoute path={EMPLOYEES} exact>
              <Employees />
            </PrivateRoute>
            <PrivateRoute path={PROFILE} exact>
              <Profile />
            </PrivateRoute>
            <PrivateRoute path={EMPLOYEE} exact>
              <Employee />
            </PrivateRoute>
            {/* <PrivateRoute path={SETTINGS} exact>
            <Settings />
          </PrivateRoute> */}
            {/* <PrivateRoute path={REPORTS} exact>
            <Reports />
          </PrivateRoute> */}

            <Route path={LOGIN} exact>
              <Login />
            </Route>
            <Route path={LOGGED_IN}>
              <LoggedIn />
            </Route>
            <Route path={SIGNUP}>
              <Signup />
            </Route>
            <Route path={SIGNEDUP}>
              <SignedUp />
            </Route>
            <Route path={FORGOT_PASSWORD} exact>
              <ForgotPassword />
            </Route>
            <Route path={RENEW_PASSWORD} component={RenewPassword} />
            <Route path={ERROR}>
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </NavbarExtendedContext>
    </ProvideAuth>
  );
};

export default Router;
