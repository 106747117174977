import { useMemo, useState } from 'react';
import TableRow from 'components/atoms/TableRow';
import UserPicture from 'components/atoms/UserPicture';

import type { User } from 'types';
import ActionsButton from 'components/atoms/ActionsButton';
import { DropdownItem, DropdownItemType } from 'types/DropdownItem';
import {
  faTimes,
  faCheck,
  faEye,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/molecules/Dropdown';
import { EMPLOYEES } from 'services/routes';

interface Props {
  employee: User;
  // mapRoles: (roleId: number) => ReactElement;
  onDeactivate: (employeeId: number) => void;
  onActivate: (employeeId: number) => Promise<void>;
  onRenewPassword: (employeeMail: string) => void;
}

const EmployeeTableRow: React.FC<Props> = ({
  employee,
  // mapRoles,
  onDeactivate,
  onActivate,
  onRenewPassword,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownItems = useMemo<DropdownItem[]>(() => {
    const items: DropdownItem[] = [];
    items.push({
      type: DropdownItemType.LINK,
      icon: <FontAwesomeIcon icon={faEye} />,
      to: { pathname: `${EMPLOYEES}/${employee.id}` },
      color: 'primary',
      text: 'Vis',
    });
    items.push({
      type: DropdownItemType.BUTTON,
      icon: <FontAwesomeIcon icon={faRedo} />,
      onClick: () => onRenewPassword(employee.email),
      color: 'primary',
      text: 'Forespørg ny kode',
    });
    if (employee.disabled) {
      items.push({
        type: DropdownItemType.BUTTON,
        icon: <FontAwesomeIcon icon={faCheck} />,
        onClick: () =>
          onActivate(employee.id).then(() => {
            setShowDropdown(false);
          }),
        text: 'Aktiver',
      });
    } else {
      items.push({
        type: DropdownItemType.BUTTON,
        icon: <FontAwesomeIcon icon={faTimes} />,
        onClick: () => onDeactivate(employee.id),
        color: 'danger',
        text: 'Deaktiver',
      });
    }

    return items;
  }, [
    employee.disabled,
    employee.id,
    employee.email,
    onActivate,
    onDeactivate,
    onRenewPassword,
  ]);

  return (
    <TableRow
      border
      className={`text-grayDarkest font-header text-left ${
        employee.disabled ? 'bg-gray' : ''
      }`}
    >
      {/* <td className="text-center w-[50px]">
      <input type="checkbox" />
    </td> */}
      <td className="flex justify-center p-1.5">
        <UserPicture employee={employee} />
      </td>
      {/* <td>{employee.id}</td> */}
      <td
        className={employee.disabled ? 'text-red-500 line-through' : undefined}
      >{`${employee.firstName} ${employee.lastName}`}</td>
      {/* TODO map rolleid til roller */}
      {/* <td>{mapRoles(employee.employeeRoleId)}</td> */}
      <td className="text-center">
        <ActionsButton
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        />
        <Dropdown
          collapsed={!showDropdown}
          className="absolute"
          items={dropdownItems}
        />
      </td>
    </TableRow>
  );
};

export default EmployeeTableRow;
