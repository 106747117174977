import React, { ReactNode, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  itemName: string;
  isLast?: boolean;
  icon: ReactNode;
  path: string;
  exact?: boolean;
}
const NavbarItem: React.FC<Props> = ({
  itemName,
  isLast = false,
  icon,
  path,
  exact = false,
}) => {
  const [active, setActive] = useState(false);
  const isActive = useCallback((match) => {
    const isMatch = !!match;

    setActive(isMatch);
    return isMatch;
  }, []);
  return (
    <div
      className={`w-full border-grayDarker border-t h-16 ${
        isLast ? 'border-b' : ''
      }`}
    >
      <NavLink
        className={`flex m-2 h-11 items-center rounded ${
          active ? 'active bg-primary text-white' : 'text-black'
        }`}
        to={path}
        exact={exact}
        isActive={isActive}
      >
        <IconWrapper
          className={`w-6 h-6 mr-4 ml-3 flex-shrink-0 ${
            active ? 'filter invert brightness-0' : ''
          }`}
        >
          {icon}
        </IconWrapper>
        <div className="overflow-hidden">{itemName}</div>
      </NavLink>
    </div>
  );
};

const IconWrapper = styled.div`
  & svg {
    height: 100%;
    width: 100%;
  }
`;

export default NavbarItem;
