import React, { FormEvent, memo, useCallback } from 'react';
import Button from '../../atoms/Button';

interface Props {
  className?: string;
  handleSubmit: () => void;
  submitButtonValue?: string;
  submitButtonSize?: 'sm' | 'md' | 'lg' | 'full';
  children: JSX.Element;
  invalid?: boolean;
}
const Form: React.FC<Props> = ({
  className = '',
  handleSubmit,
  submitButtonValue = 'Submit',
  submitButtonSize = 'md',
  children,
  invalid = false,
}) => {
  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      return handleSubmit();
    },
    [handleSubmit]
  );
  return (
    <form
      onSubmit={onSubmit}
      className={`flex flex-col px-2 lg:px-8 py-6 ${className}`}
    >
      {children}
      <Button
        size={submitButtonSize}
        type="submit"
        value={submitButtonValue}
        className="self-end"
        disabled={invalid}
      />
    </form>
  );
};

export default memo(Form);
