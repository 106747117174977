const colors = {
  primary: '#F59E0B',
  secondary: '#3747F4',
  success: '#06D6A0',
  danger: '#EF4444',
  text: {
    main: '#FFFFFF',
    dark: '#000839',
    light: '#FFFFFF',
  },
};

const theme = {
  colors,
};

export default theme;
