import { omit } from 'lodash';
import { EmployeeInvitation } from 'types';
import { EmployeeInvitationResponse } from '../types';

const normalizeEmployeeInvitation = (
  employeeInvitationResponse: EmployeeInvitationResponse
): EmployeeInvitation => ({
  ...omit(employeeInvitationResponse, 'attributes'),
  ...employeeInvitationResponse.attributes,
});

export default normalizeEmployeeInvitation;
