import React, { ReactElement } from 'react';
import SEO from 'components/templates/SEO';
import Navbar from 'components/organism/Navbar';
import SiteHeader from 'components/organism/SiteHeader';
import styled from 'styled-components';
import { useNavbarExtendedContext } from 'services/navbar';

type Props = {
  pageTitle: string;
  popup?: ReactElement;
  showPopup?: boolean;
  // navBarItems:
};

const ViewBase: React.FC<Props> = ({
  pageTitle,
  children,
  popup,
  showPopup = false,
  // navBarItems
}) => {
  const [navbarExtended, setNavbarExtended] = useNavbarExtendedContext();

  return (
    <>
      <SEO title={pageTitle} />
      <div className="fixed w-3/6 inset-2/4 z-50">{showPopup && popup}</div>
      <div
        className={`w-screen h-screen transition-opacity duration-300 ease-rapid-in overflow-x-hidden ${
          showPopup ? 'opacity-20' : 'opacity-100'
        }`}
      >
        <Navbar
          extended={navbarExtended}
          className={`pointer-events-${showPopup ? 'none' : 'auto'}`}
          handleExtended={setNavbarExtended}
        />
        <Wrapper
          className={`h-full transition-all duration-300 linear ${
            navbarExtended ? 'ml-80' : 'ml-16'
          }`}
        >
          <SiteHeader title={pageTitle} />
          <div
            className={`w-full pointer-events-${showPopup ? 'none' : 'auto'}`}
          >
            {children}
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const Wrapper = styled.div``;

export default ViewBase;
