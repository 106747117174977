import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import TableRow from '../TableRow';

interface Props {
  pathname: string;
  border?: boolean;
  className?: string;
}

const LinkTableRow: React.FC<Props> = ({
  pathname,
  children,
  border = false,
  className = '',
}) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const handleClick = () => {
    setClicked(true);
  };
  if (clicked) return <Redirect to={pathname} />;
  return (
    <TableRow
      handleClick={handleClick}
      className={`hover:bg-gray transition-colors cursor-pointer ${className}`}
      border={border}
    >
      {children}
    </TableRow>
  );
};

export default LinkTableRow;
