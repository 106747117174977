import Popup from 'components/atoms/Popup';
import TextInput from 'components/atoms/TextInput';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import Api, { CONSTANTS } from 'services/api';
import employeeInvitationSlice from 'store/employeeInvitations/slice';
import type { InviteEmployeeInvitation } from 'types';

import Form from '../Form';

interface Props {
  onClick: () => void;
}

const InvitePopup: React.FC<Props> = ({ onClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');

  useEffect(() => {
    swapSibling(
      document.getElementsByClassName('left-2 w-full web react-tel-input')[0]
    );
  }, []);

  const swapSibling = (node1?: Element) => {
    if (node1) {
      const labelElem = node1.children[0];
      const inputElem = node1.children[1];
      node1.replaceChild(labelElem, inputElem);
      node1.insertBefore(inputElem, labelElem);
    }
  };

  const handleSubmit = () => {
    const employeeInvitation: InviteEmployeeInvitation = {};

    if (email.length > 0) employeeInvitation.email = email;
    if (phone.length > 3) employeeInvitation.phoneNumber = `+${phone}`;

    Api.post(CONSTANTS.EMPLOYEE_INVITATIONS, { employeeInvitation })
      .then((response) => {
        if (response) {
          setErrors('');
          setShowSuccess(true);
          setTimeout(onClick, 1000);
        }
        dispatch(
          employeeInvitationSlice.actions.fetchPendingEmployeeInvitations()
        );
      })
      .catch((error) => {
        setErrors(error.response.data.message);
      });
  };

  return (
    <Popup
      headerText="Invitér en medarbejder"
      headerSize="lg"
      onClick={onClick}
    >
      <Form handleSubmit={() => handleSubmit()} submitButtonValue="Invitér">
        <>
          <TextInput
            placeholder="Email"
            name="email"
            onChange={(value) => setEmail(value)}
          />
          <span className="mb-4">ELLER</span>
          <PhoneInput
            placeholder="Telefonnummer"
            inputProps={{ name: 'phone', id: 'phoneInputweb' }}
            country="dk"
            value={phone}
            onChange={(phoneVal) => setPhone(phoneVal)}
            inputClass="border-primary w-full lg:text-lg"
            containerClass="left-2 w-full web"
            specialLabel="Telefonnummer"
          />
          {showSuccess && (
            <span className="text-green-500">
              Medarbejder succesfuldt inviteret
            </span>
          )}
          {errors && <span className="text-red-500">{errors}</span>}
        </>
      </Form>
    </Popup>
  );
};

export default InvitePopup;
