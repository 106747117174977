import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import React, { useEffect, useState } from 'react';
import validatePassword, {
  matchPasswords,
} from 'services/validations/validatePassword';
import { ScreenSize } from 'types';

interface Props {
  buttonSize?: 'sm' | 'md' | 'lg' | 'full';
  handleSubmit: (newPassword: string) => void;
  screenSize: ScreenSize;
  success: boolean;
  errors: string;
}

const RenewPasswordForm: React.FC<Props> = ({
  buttonSize = 'md',
  handleSubmit,
  screenSize,
  success,
  errors,
}) => {
  const [formValid, setFormValid] = useState<boolean>(false);
  const [newPass, setNewPass] = useState<string>('');
  const [checkNewPass, setCheckNewPass] = useState<string>('');

  useEffect(() => {
    setFormValid(
      validatePassword(newPass) &&
        matchPasswords(checkNewPass, newPass) &&
        newPass.length > 0 &&
        checkNewPass.length > 0
    );
  }, [newPass, checkNewPass]);

  const getFormContents = () => {
    const inputClass =
      'text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none';

    return (
      <>
        <TextInput
          name="newPassword"
          type="password"
          placeholder="Nyt kodeord"
          className={inputClass}
          labelSize="text-xs lg:text-sm"
          value={newPass}
          onChange={setNewPass}
          invalid={!validatePassword(newPass)}
          screenSize={screenSize}
        />
        <TextInput
          name="repPassword"
          type="password"
          placeholder="Gentag kodeord"
          className={inputClass}
          labelSize="text-xs lg:text-sm"
          value={checkNewPass}
          onChange={setCheckNewPass}
          invalid={!matchPasswords(checkNewPass, newPass)}
          screenSize={screenSize}
        />
      </>
    );
  };

  return (
    <Form
      handleSubmit={() => {
        handleSubmit(newPass);
      }}
      submitButtonSize={buttonSize}
      submitButtonValue="Skift kode"
      invalid={!formValid}
    >
      <div>
        {getFormContents()}
        {errors && <span className="text-danger">{errors}</span>}
        {success && (
          <span className="text-green-500 pl-4">
            Dit kodeord er blevet skiftet. Du bliver viderestillet til login...
          </span>
        )}
      </div>
    </Form>
  );
};

export default RenewPasswordForm;
