import i18n from 'i18next';
import da from 'assets/translations/da.json';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: { da },
  fallbackLng: 'da',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
