import { useContext } from 'react';
import type { ProvidedAuth } from 'types';

import authContext from './authContext';

const useAuth = (): ProvidedAuth | null => {
  return useContext(authContext);
};

export default useAuth;
