import React from 'react';

interface Props {
  className?: string;
  padding?: boolean;
}

const Card: React.FC<Props> = ({ className, children, padding = true }) => {
  return (
    <div className={`${padding ? 'p-4' : ''}  shadow-md rounded ${className}`}>
      {children}
    </div>
  );
};

export default Card;
