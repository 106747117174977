import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
};

const SEO: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation('app');
  return (
    <Helmet
      htmlAttributes={{
        lang: 'da',
      }}
      title={`${t('name')} | ${title}`}
    >
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={t('desc')} />
    </Helmet>
  );
};

export default SEO;
