import Popup from 'components/atoms/Popup';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Api, { CONSTANTS } from 'services/api';
import employeeInvitationSlice from 'store/employeeInvitations/slice';
import Form from '../Form';

interface Props {
  onClick: () => void;
  employeeInvitationId: number;
}

const DeletePopup: React.FC<Props> = ({ onClick, employeeInvitationId }) => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');

  const handleDelete = () => {
    Api.delete(`${CONSTANTS.EMPLOYEE_INVITATIONS}/${employeeInvitationId}`)
      .then(() => {
        setErrors('');
        setShowSuccess(true);
        setTimeout(onClick, 1000);
        dispatch(
          employeeInvitationSlice.actions.fetchPendingEmployeeInvitations()
        );
      })
      .catch((error) => {
        setErrors(error.response.data.message);
      });
  };

  return (
    <Popup headerText="Slet invitation?" headerSize="lg" onClick={onClick}>
      <Form handleSubmit={handleDelete} submitButtonValue="Slet">
        <>
          {showSuccess && (
            <span className="text-green-500">
              Medarbejderinvitation succesfuldt slettet
            </span>
          )}
          {errors && <span className="text-red-500">{errors}</span>}
        </>
      </Form>
    </Popup>
  );
};

export default DeletePopup;
