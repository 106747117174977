import { useEffect, useState, useCallback } from 'react';
import { AuthObject, ProvidedAuth } from 'types';

let initialized = false;

const initialize = async (
  authObject: AuthObject,
  loggedIn: (token: string) => Promise<void>,
  signedOut: () => Promise<void>
): Promise<void> => {
  if (initialized) return;
  initialized = true;

  const token = await authObject.initialize();

  if (token) {
    await loggedIn(token);
  } else {
    await signedOut();
  }
};

const useProvidedAuth = (authObject: AuthObject): ProvidedAuth => {
  const [user, setUser] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const loggedIn = useCallback(
    (token: string): Promise<void> => {
      setUser('user');

      return authObject.loggedIn(token);
    },
    [authObject]
  );

  const signOut = useCallback((): Promise<void> => {
    setUser(null);

    return authObject.signOut();
  }, [authObject]);

  useEffect(() => {
    initialize(authObject, loggedIn, signOut).then(() => {
      setLoaded(true);
    });
  }, [authObject, loggedIn, signOut]);

  return {
    initialized: loaded,
    user,
    loggedIn,
    signOut,
  };
};

export default useProvidedAuth;
