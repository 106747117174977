import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { EmployeeInvitation } from 'types';
import Api, { CONSTANTS } from 'services/api';
import normalizeEmployeeInvitation from 'services/api/employeeInvitation/normalize';
import type { EmployeeInvitationResponse } from 'services/api/types';

import employeeInvitationSlice from './slice';

function* pendingEmployeeInvitationsFetch() {
  try {
    const employeeInvitations: Array<EmployeeInvitationResponse> =
      yield Api.get(CONSTANTS.PENDING_EMPLOYEE_INVITATIONS).then(
        (result) => result.data.data
      );
    const normalizeEmployeeInvitations: Array<EmployeeInvitation> =
      yield employeeInvitations.map(normalizeEmployeeInvitation);
    yield put(
      employeeInvitationSlice.actions.fetchEmployeeInvitationsSuccess(
        normalizeEmployeeInvitations
      )
    );
  } catch (error) {
    yield put(
      employeeInvitationSlice.actions.fetchEmployeeInvitationsFailed(
        error.message
      )
    );
  }
}

function* employeeInvitationsFetch() {
  try {
    const employeeInvitations: Array<EmployeeInvitationResponse> =
      yield Api.get(CONSTANTS.EMPLOYEE_INVITATIONS).then(
        (result) => result.data.data
      );
    const normalizeEmployeeInvitations: Array<EmployeeInvitation> =
      yield employeeInvitations.map(normalizeEmployeeInvitation);
    yield put(
      employeeInvitationSlice.actions.fetchEmployeeInvitationsSuccess(
        normalizeEmployeeInvitations
      )
    );
  } catch (error) {
    yield put(
      employeeInvitationSlice.actions.fetchEmployeeInvitationsFailed(
        error.message
      )
    );
  }
}

function* onEmployeeInvitationsFetch(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    employeeInvitationSlice.actions.fetchEmployeeInvitations,
    employeeInvitationsFetch
  );
}

function* onPendingEmployeeInvitationsFetch(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    employeeInvitationSlice.actions.fetchPendingEmployeeInvitations,
    pendingEmployeeInvitationsFetch
  );
}

const sagas = [onEmployeeInvitationsFetch, onPendingEmployeeInvitationsFetch];
export default sagas;
