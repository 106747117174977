import React, { memo, useEffect, useRef, useState } from 'react';
import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import 'react-phone-input-2/lib/material.css';
import { isEmpty } from 'lodash';
import Api, { CONSTANTS } from 'services/api';
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { validateEmail } from 'services/validations';
import normalizeEmployeeInvitation from 'services/api/employeeInvitation/normalize';
import type { EmployeeInvitation } from 'types';
import type { EmployeeInvitationResponse } from 'services/api/types';
import validatePassword, {
  matchPasswords,
} from 'services/validations/validatePassword';

interface Props {
  buttonSize?: 'sm' | 'md' | 'lg' | 'full';
  handleSubmit: (
    token: string,
    firstName: string,
    lastName: string,
    password: string,
    email: string,
    phone: string
  ) => void;
  id: string;
}

interface ApiResponse {
  data: EmployeeInvitationResponse;
}

const SignupForm: React.FC<Props> = ({
  buttonSize = 'md',
  handleSubmit,
  id,
}) => {
  const firstRender = useRef(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [repeatPass, setRepeatPass] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  // const [country, setCountry] = useState<string>('');
  const [employeeInvitation, setEmployeeInvitation] =
    useState<EmployeeInvitation>();
  const [token, setToken] = useState<string>('');
  const location = useLocation();
  const inputClass =
    'text-5xl lg:text-lg border-4 lg:border rounded-xl lg:rounded filter drop-shadow-lg lg:drop-shadow-none py-9 lg:py-4';

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      swapSibling(
        document.getElementsByClassName(
          `left-2 w-full ${id} react-tel-input`
        )[0]
      );
      setToken(new URLSearchParams(location.search).get('token') || '');
    }
  }, [firstRender, location.search, token, id]);

  useEffect(() => {
    if (token) {
      Api.get<ApiResponse>(`${CONSTANTS.SIGNUP}?token=${token}`).then(
        ({ data }) => {
          const invitation = normalizeEmployeeInvitation(data.data);
          if (invitation) {
            setEmployeeInvitation(invitation);
            setEmail(invitation.email || '');
            setPhone(invitation.phoneNumber || '');
          }
        }
      );
    }
  }, [token]);

  const swapSibling = (node1?: Element) => {
    if (node1) {
      const labelElem = node1.children[0];
      const inputElem = node1.children[1];
      node1.replaceChild(labelElem, inputElem);
      node1.insertBefore(inputElem, labelElem);
    }
  };

  const getFormContents = () => {
    return (
      <>
        <div className="flex justify-between">
          <TextInput
            name="firstName"
            type="text"
            onChange={(value) => setFirstName(value)}
            placeholder="Fornavn"
            wrapperClass="w-3/6 mr-2"
            labelSize="text-2xl lg:text-sm"
            value={firstName}
            className={inputClass}
          />
          <TextInput
            name="lastName"
            type="text"
            placeholder="Efternavn"
            wrapperClass="w-3/6 ml-2"
            className={inputClass}
            labelSize="text-2xl lg:text-sm"
            value={lastName}
            onChange={(value) => setLastName(value)}
          />
        </div>
        <TextInput
          name="password"
          type="password"
          id="password"
          placeholder="Password"
          className={inputClass}
          labelSize="text-2xl lg:text-sm"
          errorMessage="Kodeord skal være mindst 8 karakterer langt"
          invalid={!validatePassword(pass)}
          value={pass}
          onChange={(value) => setPass(value)}
        />
        <TextInput
          name="password"
          type="password"
          id="password_confirmation"
          placeholder="Gentag password"
          className={inputClass}
          errorMessage="Matcher ikke dit kodeord"
          invalid={!matchPasswords(repeatPass, pass)}
          labelSize="text-2xl lg:text-sm"
          value={repeatPass}
          onChange={(value) => setRepeatPass(value)}
        />
        <PhoneInput
          placeholder="Telefonnummer"
          inputProps={{ name: 'phone', id: `phoneInput${id}` }}
          country="dk"
          value={phone}
          onChange={(phoneVal) => setPhone(phoneVal)}
          disabled={!isEmpty(employeeInvitation?.phoneNumber)}
          inputClass="border-primary w-full lg:text-lg mobile"
          containerClass={`left-2 w-full ${id}`}
          specialLabel="Telefonnummer"
        />
        <TextInput
          name="email"
          type="email"
          placeholder="Email"
          className={inputClass}
          errorMessage="Indtast en valid email"
          invalid={validateEmail(email)}
          disabled={!isEmpty(employeeInvitation?.email)}
          labelSize="text-2xl lg:text-sm"
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </>
    );
  };

  /* const validatePhone = () => {
    if (phone.match(/\d{8}/) || phone.length === 0) return false;
    return true;
  }; */

  return (
    <Form
      handleSubmit={() => {
        handleSubmit(token, firstName, lastName, pass, email, phone);
      }}
      submitButtonSize={buttonSize}
      submitButtonValue="Opret bruger"
    >
      {getFormContents()}
    </Form>
  );
};

export default memo(SignupForm);
