import React from 'react';

interface Props {
  text: string;
}
const TableHeader: React.FC<Props> = ({ text }) => (
  <span className="mr-4 text-primary font-header">{text}</span>
);

export default TableHeader;
