import Card from 'components/atoms/Card';
import React, { useEffect } from 'react';
import ViewBase from 'components/templates/ViewBase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { selectCurrentEmployee } from 'store/employees';
import { getParsedToken } from 'services/auth/token';
import employeeSlice from 'store/employees/slice';
import EmployeeInfo from 'components/organism/EmployeeInfo';
import Header from 'components/atoms/Header';
import ChangePasswordForm from 'components/organism/ChangePasswordForm';

const Profile: React.FC = () => {
  const dispatch = useDispatch();

  const currentEmployee = useSelector((state: RootState) =>
    selectCurrentEmployee(state)
  );

  useEffect(() => {
    if (!currentEmployee) {
      const authData = getParsedToken();
      if (authData) {
        dispatch(employeeSlice.actions.fetchEmployee(authData.subject.id));
      }
    }
  }, [currentEmployee, dispatch]);

  return (
    <ViewBase pageTitle="Din profil">
      <div className="px-10 h-full w-full">
        {currentEmployee && (
          <>
            <Card
              padding={false}
              className="-top-8 relative bg-white z-20 overflow-y-hidden"
            >
              <EmployeeInfo employee={currentEmployee} />
            </Card>
            <Card className="relative bg-white z-20 overflow-y-hidden">
              <Header size="lg" className="font-header">
                Skift kodeord
              </Header>
              <ChangePasswordForm />
            </Card>
          </>
        )}
      </div>
    </ViewBase>
  );
};

export default Profile;
