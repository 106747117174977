import React, { useMemo, useEffect, useState } from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from 'services/auth';
import { HOME } from 'services/routes';

const LoggedIn: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [forwardTo, setForwardTo] = useState<RedirectProps['to']>(null);
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const token = useMemo(() => {
    if (!searchParams.has('token')) throw new Error('no auth token present');

    return searchParams.get('token') || '';
  }, [searchParams]);
  const from = useMemo(() => {
    if (!searchParams.has('from')) return null;

    return JSON.parse(searchParams.get('from') || '');
  }, [searchParams]);

  useEffect(() => {
    if (loggedIn) return;

    setLoggedIn(true);
    auth?.loggedIn(token);

    if (from) {
      setForwardTo(from);
      return;
    }

    setForwardTo({ pathname: HOME });
  }, [token, auth, loggedIn, from, dispatch, setForwardTo]);

  if (forwardTo) return <Redirect to={forwardTo} />;

  return null;
};

export default LoggedIn;
