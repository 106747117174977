import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { InputHTMLAttributes, ChangeEvent } from 'react';
import type { ScreenSize } from 'types';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  inputSize?: 'sm' | 'md' | 'lg';
  labelSize?: string;
  onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  invalid?: boolean;
  errorMessage?: string;
  bottomElem?: JSX.Element;
  wrapperClass?: string;
  disabled?: boolean;
  screenSize?: ScreenSize;
}

const defaultIfNotIncluded = (className: string, prop: string) => {
  return className.includes(prop) ? '' : prop;
};

const determineInputColor = (disabled: boolean, invalid: boolean) => {
  if (disabled) return 'border-gray-500 focus:ring-gray-500';
  if (invalid) return 'border-red-500 focus:ring-red-500';
  return 'border-primary focus:ring-primary';
};

const determineTextColor = (disabled: boolean, invalid: boolean) => {
  if (disabled) return 'text-gray-500';
  if (invalid) return 'text-red-500';
  return 'text-primary';
};

const TextInput: React.FC<Props> = ({
  inputSize = 'lg',
  labelSize = 'text-sm',
  type,
  className = '',
  onChange,
  placeholder,
  name,
  invalid = false,
  errorMessage = 'Input is invalid',
  bottomElem,
  wrapperClass,
  disabled = false,
  value,
  screenSize = 'lg',
  ...rest
}: Props) => {
  const [inputColor, setInputColor] = useState<string>('');
  const [textColor, setTextColor] = useState<string>('');

  useEffect(() => {
    setInputColor(determineInputColor(disabled, invalid));
  }, [disabled, invalid]);

  useEffect(() => {
    setTextColor(determineTextColor(disabled, invalid));
  }, [disabled, invalid]);

  const classes = useMemo(() => {
    let c = `${defaultIfNotIncluded(
      className,
      'rounded'
    )} w-full ${defaultIfNotIncluded(
      className,
      'border'
    )} focus:outline-none focus:ring-1 ${inputColor}
     disabled:opacity-50 disabled:border-gray-200 ${className} `;

    switch (inputSize) {
      case 'sm':
        c += 'p-1 m-1';
        break;
      case 'md':
        c += 'px-2 py-4 m-2';
        break;
      case 'lg':
        c += 'px-2 py-3 my-2 text-md';
    }
    return c;
  }, [className, inputColor, inputSize]);
  return (
    <Wrapper className={wrapperClass} screenSize={screenSize}>
      <input
        className={classes}
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value, event)}
        disabled={disabled}
        value={value}
        {...rest}
      />
      <label htmlFor={name} className={`${labelSize} ${textColor}`}>
        {placeholder}
      </label>
      <div className={bottomElem && 'flex justify-between'}>
        <span
          className={`${labelSize} px-4 text-red-500 flex ${
            !invalid ? 'invisible' : ''
          }`}
        >
          {errorMessage}
        </span>
        {bottomElem}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div<{ screenSize: ScreenSize }>`
  position: relative;
  appearance: none;
  label {
    opacity: 0;
    display: none;
  }
  input:not(:placeholder-shown) ~ label {
    position: absolute;
    transition: all 0.9s ease-out;
    display: block;
    background: white;
    top: ${({ screenSize }) => (screenSize === 'lg' ? '-5px' : '-3px')};
    left: ${({ screenSize }) => (screenSize === 'lg' ? '20x' : '12px')};
    padding: 0 ${({ screenSize }) => (screenSize === 'lg' ? '10px' : '6px')};
    transition: all 0.15s;
    opacity: 1;
  }
`;
export default TextInput;
