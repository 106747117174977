import React from 'react';
import { useProvidedAuth, authContext } from 'services/auth';
import { AuthObject } from 'types';

interface Props {
  authObject: AuthObject;
}

const ProvideAuth: React.FC<Props> = ({ authObject, children }) => {
  const auth = useProvidedAuth(authObject);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default ProvideAuth;
