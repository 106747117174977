import Header from 'components/atoms/Header';
import UserInfo from 'components/molecules/UserInfo';
import React from 'react';
import { RootState } from 'store/types';
import { selectCurrentEmployee } from 'store/employees';
import { useSelector } from 'react-redux';

interface Props {
  title: string;
}

const SiteHeader: React.FC<Props> = ({ title }) => {
  const employee = useSelector((state: RootState) =>
    selectCurrentEmployee(state)
  );

  return (
    <>
      {employee && <UserInfo employee={employee} />}
      <div className="w-screen flex items-center bg-white h-80 sticky -top-56 z-40">
        <header className="w-screen flex items-center bg-dimYellow h-80 sticky -top-56 z-40">
          <div className="h-24 sticky top-0 w-full">
            <div className="ml-4">
              <Header size="3xl">{title}</Header>
            </div>
            {/* TODO add subheader */}
          </div>
        </header>
      </div>
      <div className="h-10 sticky top-50 w-full bg-dimYellow" />
    </>
  );
};

export default SiteHeader;
