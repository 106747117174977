import React, { useMemo } from 'react';

import type { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md' | 'lg' | 'full';
}

const Button: React.FC<Props> = ({
  size = 'sm',
  onClick,
  className = '',
  type = 'button',
  value,
  children,
  disabled = false,
}) => {
  const classes = useMemo(() => {
    let c = `rounded text-white outline-none focus:outline-none hover:ring-4 hover:ring-opacity-50 focus:ring-4  focus:ring-opacity-50 ${className} `;
    if (disabled) {
      c +=
        'bg-grayDarkest hover:ring-grayDarkest focus:ring-grayDarkest cursor-default ';
    } else {
      c += 'bg-primary hover:ring-primary focus:ring-primary ';
    }

    switch (size) {
      case 'sm':
        c += 'p-1 m-1 text-sm w-24';
        break;
      case 'md':
        c += 'px-2 py-4 my-2 text-lg w-36';
        break;
      case 'lg':
        c += 'px-4 py-8 my-4 text-3xl w-60';
        break;
      case 'full':
        c += 'py-3 my-8 text-md w-full';
        break;
    }

    return c;
  }, [size, className, disabled]);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || 'submit'}
      value={value}
      onClick={onClick}
      className={classes}
      disabled={disabled}
    >
      {children ?? value}
    </button>
  );
};

export default Button;
