import Popup from 'components/atoms/Popup';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Api, { CONSTANTS } from 'services/api';
import employeeSlice from 'store/employees/slice';
import Form from '../Form';

interface Props {
  onClick: () => void;
  employeeId: number;
}

const DeactivateEmployeePopup: React.FC<Props> = ({ onClick, employeeId }) => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');

  const handleDeactivate = () => {
    Api.post(`${CONSTANTS.EMPLOYEES}/${employeeId}/set_disable_status`, {
      disable: true,
    })
      .then(() => {
        setErrors('');
        setShowSuccess(true);
        setTimeout(onClick, 1000);
        dispatch(employeeSlice.actions.fetchEmployees());
      })
      .catch((error) => {
        setErrors(error.response.data.message);
      });
  };

  return (
    <Popup headerText="Deaktiver medarbejder" headerSize="lg" onClick={onClick}>
      <Form handleSubmit={handleDeactivate} submitButtonValue="Slet">
        <>
          {showSuccess && (
            <span className="text-green-500">Medarbejderen er deaktiveret</span>
          )}
          {errors && <span className="text-red-500">{errors}</span>}
        </>
      </Form>
    </Popup>
  );
};

export default DeactivateEmployeePopup;
