import UserPicture from 'components/atoms/UserPicture';
import React, { useCallback, useMemo, useState } from 'react';
import { User } from 'types';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/icons/Dropdown.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg';
import { DropdownItem, DropdownItemType } from 'types/DropdownItem';
import { useAuth } from 'services/auth';
import { PROFILE } from 'services/routes';
import { Link } from 'react-router-dom';

import Dropdown from '../Dropdown';

interface Props {
  employee: User;
}
const UserInfo: React.FC<Props> = ({ employee }) => {
  const auth = useAuth();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const toggleShowDropdown = useCallback(
    () => setShowDropdown(!showDropdown),
    [showDropdown]
  );
  const dropdownItems = useMemo<DropdownItem[]>(
    () => [
      {
        type: DropdownItemType.BUTTON,
        icon: <LogoutIcon />,
        onClick: () => auth?.signOut(),
        color: 'danger',
        text: 'Log ud',
      },
    ],
    [auth]
  );

  return (
    <div className="fixed top-6 right-6 z-50 flex items-center">
      <div className="flex flex-row">
        <div className="mr-4">
          <span className="font-header mr-2">{`${employee.firstName} ${employee.lastName}`}</span>
          <button
            type="button"
            className={`transition-all duration-150 ${
              showDropdown ? 'rotate-180' : 'rotate-0'
            }`}
            onClick={toggleShowDropdown}
          >
            <Icon width="14" height="8" viewBox="0 0 14 8" fill="none" />
          </button>
        </div>
        <StyledDropdown
          className="top-10 absolute"
          collapsed={!showDropdown}
          items={dropdownItems}
        />
      </div>

      <Link to={PROFILE}>
        <UserPicture employee={employee} />
      </Link>
    </div>
  );
};

const StyledDropdown = styled(Dropdown)`
  right: 88px;
`;

export default UserInfo;
