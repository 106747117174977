import React from 'react';

import Card from 'components/atoms/Card';
import Header from 'components/atoms/Header';
import Logo from 'components/atoms/Logo';
// import SEO from 'components/templates/SEO';

import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from '../../../constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactStoreBadges = require('react-store-badges').default;

const SignedUp: React.FC = () => {
  const contents = () => (
    <div>
      Din bruger er blevet succesfuldt oprettet. For at fortsætte skal du
      downloade appen, og logge ind der:
      <div className="flex justify-between w-2/5 m-auto">
        <ReactStoreBadges
          platform={'android'}
          locale="da-get"
          url={GOOGLE_PLAY_LINK}
        />
        <ReactStoreBadges
          platform={'ios'}
          locale="da-dk"
          url={APP_STORE_LINK}
        />
      </div>
    </div>
  );
  return (
    <>
      {/* <SEO title="Signup" /> */}
      <div
        className="w-6/6 lg:w-4/6 max-w-xs h-full items-center justify-center lg:container mx-auto mt-32"
        style={{ maxWidth: '880px' }}
      >
        <Logo
          className="text-center w-full text-9xl py-36 lg:py-0"
          size="text-9xl lg:text-7xl"
        />
        <div className="flex flex-col">
          <Header size="2xl">Bruger oprettet</Header>
          <Header size="MD">Fortsæt ved at logge ind i appen</Header>
        </div>
        <Card className="my-4 border-t-8 border-primary w-6/6 p-12 hidden lg:block text-xl">
          {contents()}
        </Card>
        <div className="block lg:hidden text-4xl pt-24">{contents()}</div>
      </div>
    </>
  );
};

export default SignedUp;
