import React, { memo, useEffect, useRef, useState } from 'react';
import TableRow from 'components/atoms/TableRow';
import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import PhoneInput from 'react-phone-input-2';
import { User } from 'types';

interface Props {
  handleEdit: (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) => void;
  editing: boolean;
  employee: User;
  errors: string;
  success: boolean;
}

const ProfileForm: React.FC<Props> = ({
  handleEdit,
  editing,
  employee,
  errors,
  success,
}) => {
  const firstRender = useRef<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const swapSibling = (node1?: Element) => {
    if (node1) {
      const labelElem = node1.children[0];
      const inputElem = node1.children[1];
      node1.replaceChild(labelElem, inputElem);
      node1.insertBefore(inputElem, labelElem);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      swapSibling(document.getElementsByClassName(`w-full react-tel-input`)[0]);
    }
  }, [firstRender]);

  useEffect(() => {
    if (employee) {
      setFirstName(employee.firstName);
      setLastName(employee.lastName);
      setPhone(`${employee.phoneNumber}`);
      setEmail(employee.email);
    }
  }, [employee]);

  const getFormContents = () => {
    return (
      <table className="w-full">
        <tbody>
          <TableRow className="text-grayDarkest font-header text-left">
            <td className="px-4 pt-4">
              <TextInput
                placeholder="Fornavn"
                name="firstName"
                value={firstName}
                disabled={!editing}
                onChange={(firstNameValue) => setFirstName(firstNameValue)}
              />
            </td>
            <td className="px-4 pt-4">
              <TextInput
                placeholder="Efternavn"
                name="lastName"
                value={lastName}
                disabled={!editing}
                onChange={(lastNameValue) => setLastName(lastNameValue)}
              />
            </td>
          </TableRow>
          <TableRow border className="text-grayDarkest font-header text-left">
            <td className="px-4 pt-4">
              <PhoneInput
                placeholder="Telefonnummer"
                inputProps={{ name: 'phone', id: 'phoneInputWeb' }}
                country="dk"
                value={phone}
                disabled={!editing}
                onChange={(phoneNumberValue) => setPhone(phoneNumberValue)}
                inputClass="border-primary w-full lg:text-lg"
                containerClass="w-full"
                specialLabel="Telefonnummer"
              />
            </td>
          </TableRow>
          <TableRow border className="text-grayDarkest font-header text-left">
            <td className="px-4 pt-4">
              <TextInput
                placeholder="Email"
                name="email"
                value={email}
                disabled={!editing}
                onChange={(emailValue) => setEmail(emailValue)}
              />
            </td>
          </TableRow>
        </tbody>
      </table>
    );
  };

  return (
    <Form
      handleSubmit={() => {
        handleEdit(firstName, lastName, email, phone);
      }}
      submitButtonValue={editing ? 'Bekræft ændringer' : 'Rediger'}
      className="w-full"
    >
      <div>
        {getFormContents()}
        {errors && <span className="text-danger">{errors}</span>}
        {success && (
          <span className="text-green-500 pl-4">Du har opdateret brugeren</span>
        )}
      </div>
    </Form>
  );
};

export default memo(ProfileForm);
