import { createSlice } from '@reduxjs/toolkit';
import type { EmployeeInvitation } from 'types';

import employeeInvitationAdapter from './entityAdapter';

export const initialState = employeeInvitationAdapter.getInitialState({
  loading: false,
  error: '',
});

const slice = createSlice({
  name: 'employeesInvitations',
  initialState,
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    employeeInvitationAdded: employeeInvitationAdapter.addOne,
    fetchEmployeeInvitations(state) {
      return {
        ...state,
        loading: true,
      };
    },
    fetchPendingEmployeeInvitations(state) {
      return {
        ...state,
        loading: true,
      };
    },
    fetchEmployeeInvitationsSuccess(
      state,
      action: { payload: Array<EmployeeInvitation> }
    ) {
      if (state.loading) {
        // Or, call them as "mutating" helpers in a case reducer
        return employeeInvitationAdapter.setAll(
          { ...state, loading: false },
          action.payload
        );
      }

      return state;
    },
    fetchEmployeeInvitationsFailed(state, action: { payload: string }) {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export default slice;
