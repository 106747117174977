import TextInput from 'components/atoms/TextInput';
import Form from 'components/molecules/Form';
import React, { useCallback, useEffect, useState } from 'react';
import Api, { CONSTANTS } from 'services/api';
import validatePassword, {
  matchPasswords,
} from 'services/validations/validatePassword';

const ChangePasswordForm: React.FC = () => {
  const [pass, setPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [checkNewPass, setCheckNewPass] = useState<string>('');
  const [errors, setErrors] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    Api.patch(`${CONSTANTS.UPDATE_PASSWORD}`, {
      oldPassword: pass,
      newPassword: newPass,
    })
      .then(() => {
        setSuccess(true);
        setErrors('');
        setPass('');
        setNewPass('');
        setCheckNewPass('');
      })
      .catch((error) => {
        setErrors(error.response.data.message);
        setSuccess(false);
        setPass('');
        setNewPass('');
        setCheckNewPass('');
      });
  }, [newPass, pass]);

  useEffect(() => {
    setFormValid(
      validatePassword(newPass) &&
        matchPasswords(checkNewPass, newPass) &&
        pass.length > 0 &&
        newPass.length > 0 &&
        checkNewPass.length > 0
    );
  }, [pass, newPass, checkNewPass]);

  return (
    <Form
      handleSubmit={handleSubmit}
      submitButtonSize="md"
      submitButtonValue="Skift kode"
      invalid={!formValid}
    >
      <div>
        <TextInput
          name="password"
          type="password"
          placeholder="Kodeord"
          className="text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none"
          labelSize="text-xs lg:text-sm"
          value={pass}
          onChange={(value) => setPass(value)}
        />
        <TextInput
          name="newPassword"
          type="password"
          placeholder="Nyt kodeord"
          className="text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none"
          labelSize="text-xs lg:text-sm"
          value={newPass}
          onChange={(value) => setNewPass(value)}
          invalid={!validatePassword(newPass)}
        />
        <TextInput
          name="repPassword"
          type="password"
          placeholder="Gentag kodeord"
          className="text-1xl lg:text-lg border rounded filter drop-shadow-lg lg:drop-shadow-none"
          labelSize="text-xs lg:text-sm"
          value={checkNewPass}
          onChange={(value) => setCheckNewPass(value)}
          invalid={!matchPasswords(checkNewPass, newPass)}
        />
        {errors && <span className="text-danger">{errors}</span>}
        {success && (
          <span className="text-green-500 pl-4">
            Du har nu ændret dit kodeord
          </span>
        )}
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
