import { Product } from 'types/Product';

export const PRODUCTS: Array<Product> = [
  {
    id: 1,
    serialNo: 'abc1234',
    itemNo: 'itemno1234',
    organisationId: 1,
    createdByEmployeeId: 1,
    createdAt: '2021-07-20T12:15:36.668Z',
    updatedAt: '2021-07-20T12:15:36.668Z',
    productManufactureId: 5,
    statusId: 4,
  },
];

const MOCK_DATA = {
  PRODUCTS,
};

export default MOCK_DATA;
