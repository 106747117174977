import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ViewBase from 'components/templates/ViewBase';
import Card from 'components/atoms/Card';
import Sorter, { SorterTypes } from 'components/atoms/Sorter';
import TableHeader from 'components/atoms/TableHeader';
import TableRow from 'components/atoms/TableRow';
import { PRODUCTS } from 'assets/data/mockdata';
import { Product } from 'types/Product';
import ColoredLabel from 'components/atoms/ColoredLabel';
import ActionsButton from 'components/atoms/ActionsButton';
import LinkTableRow from 'components/atoms/LinkTableRow';

const mapStatus = (statusID: number) => {
  switch (statusID) {
    case 1:
      return <ColoredLabel size="sm" color="secondary" text="Kræver tjek" />;
    case 2:
      return <ColoredLabel size="sm" color="green" text="Godkendt" />;
    case 3:
      return <ColoredLabel size="sm" color="primary" text="Administrator" />;
    case 4:
      return <ColoredLabel size="sm" color="red" text="Utilgængelig" />;
    default:
      return <ColoredLabel size="sm" color="red" text="Ukendt" />;
  }
};
const Tools: React.FC = () => {
  const [data, setData] = useState<Array<Product>>(PRODUCTS);
  const [lastSorted, setLastSorted] = useState<string>('');

  const sortData = useCallback(
    (key: keyof SorterTypes) => {
      const sortKey = key as keyof Product;
      const newData = [...data];
      newData.sort((a, b) => {
        if (sortKey === lastSorted) {
          // DESCENDING
          if ((a[sortKey] || 0) < (b[sortKey] || 0)) return 1;
          if ((a[sortKey] || 0) > (b[sortKey] || 0)) return -1;
        } else {
          // ASCENDING
          if ((a[sortKey] || 0) > (b[sortKey] || 0)) return 1;
          if ((a[sortKey] || 0) < (b[sortKey] || 0)) return -1;
        }
        return 0;
      });
      setLastSorted(lastSorted === sortKey ? '' : sortKey);
      setData(newData);
    },
    [data, lastSorted]
  );
  const generateTableData = useCallback(() => {
    return data.map((product) => {
      return (
        <LinkTableRow
          pathname={`/product/${product.id}`}
          key={product.id}
          border
          className="text-grayDarkest font-header text-left"
        >
          <td className="text-center">
            <input type="checkbox" />
          </td>
          <td className="flex justify-center p-1.5">BILLEDE</td>
          <td>{product.id}</td>
          <td>{product.itemNo}</td>
          {/* Måske lav lookup på item-no for at finde type? */}
          <td>{product.serialNo}</td>
          <td>{product.productManufactureId}</td>
          <td>{mapStatus(product.statusId)}</td>
          <td className="text-center">
            <ActionsButton onClick={() => {}} />
          </td>
        </LinkTableRow>
      );
    });
  }, [data]);

  const [tableRows, setTableRows] = useState<Array<ReactElement>>(
    generateTableData()
  );
  useEffect(() => {
    setTableRows(generateTableData());
  }, [data, generateTableData]);

  return (
    <ViewBase pageTitle="Produkter">
      <div className="px-10 h-full w-full text-center items-center">
        <Card
          padding={false}
          className="-top-8 relative bg-white z-20 overflow-y-hidden"
        >
          <table className="w-full">
            <thead>
              <TableRow>
                <th>
                  <input type="checkbox" />
                </th>
                <th>
                  <TableHeader text="Billede" />
                </th>
                <th>
                  <Sorter handleClick={sortData} sortKey="id" text="ID" />
                </th>
                <th>
                  <Sorter
                    handleClick={sortData}
                    sortKey="itemNo"
                    text="Produktnavn"
                  />
                </th>
                <th>
                  <Sorter
                    handleClick={sortData}
                    sortKey="serialNo"
                    text="Type"
                  />
                </th>
                <th>
                  <Sorter
                    handleClick={sortData}
                    sortKey="productManufactureId"
                    text="Producent"
                  />
                </th>
                <th>
                  <Sorter
                    handleClick={sortData}
                    sortKey="statusId"
                    text="Status"
                  />
                </th>
                <th className="pointer-events-none">
                  <TableHeader text="Etc." />
                </th>
              </TableRow>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </Card>
      </div>
    </ViewBase>
  );
};

export default Tools;
