import React, { useMemo } from 'react';

interface Props {
  size?: 'sm' | 'md' | 'Md' | 'MD' | 'lg' | 'xl' | '2xl' | '3xl';
  bold?: boolean;
  className?: string;
  children: string;
  color?: string;
}

const Header: React.FC<Props> = ({
  size = 'sm',
  bold = false,
  className = '',
  children,
  color,
}) => {
  const classes = useMemo(() => {
    let c = `my-2 ${className} `;

    switch (size) {
      case 'sm':
        c += 'text-gray text-sm';
        break;
      case 'md':
        c += 'text-gray text-md';
        break;
      case 'Md':
        c += 'text-gray text-2xl';
        break;
      case 'MD':
        c += 'text-gray text-3xl';
        break;
      case 'lg':
        c += 'text-dark text-4xl';
        break;
      case 'xl':
        c += 'text-dark text-6xl';
        break;
      case '2xl':
        c += 'text-dark text-7xl';
        break;
      case '3xl':
        c += 'text-dark text-8xl';
        break;
    }
    if (color) c = c.replace(/text-(dark|gray)/, `text-${color}`);

    if (bold) c += ' font-bold font-header';
    return c;
  }, [className, size, color, bold]);

  return <span className={classes}>{children}</span>;
};

export default Header;
