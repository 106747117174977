import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

export enum DropdownItemType {
  BUTTON = 'button',
  LINK = 'link',
}

export interface BaseDropdownItem {
  icon: ReactNode;
  type: DropdownItemType;
  text: string;
  color?: DropdownColor;
}

export interface DropdownButtonItem extends BaseDropdownItem {
  type: DropdownItemType.BUTTON;
  onClick: () => void;
}

export interface DropdownLinkItem
  extends BaseDropdownItem,
    Omit<LinkProps, 'color'> {
  type: DropdownItemType.LINK;
}

export type DropdownItem = DropdownButtonItem | DropdownLinkItem;

export type DropdownColor = 'primary' | 'secondary' | 'danger';
