import React from 'react';

interface Props {
  collapsed: boolean;
  className?: string;
  height?: string;
}
const Dropdown: React.FC<Props> = ({
  children,
  collapsed,
  className = '',
  height = 'h-auto',
}) => (
  <div
    className={`shadow-lg bg-white px-2 rounded-md duration-150 overflow-hidden linear divide-solid divide-grayDarker divide-y transition-all flex flex-col ${
      collapsed ? 'h-0' : height
    } ${className}`}
  >
    {children}
  </div>
);

export default Dropdown;
