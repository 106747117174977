import React from 'react';
import { useParams } from 'react-router-dom';
import ViewBase from 'components/templates/ViewBase';

import type { RouteParams } from 'types';

const Tool: React.FC = () => {
  const { id } = useParams<RouteParams>();
  return <ViewBase pageTitle="Tool">Tool with id: {id}</ViewBase>;
};
export default Tool;
