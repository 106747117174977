export const HOME = '/';
export const TOOLS = '/tools';
export const TOOL = `${TOOLS}/:id`;
export const LOGIN = '/login';
export const LOGGED_IN = '/loggedin';
export const LOGOUT = '/logout';
export const ERROR = '*';
export const SIGNUP = '/signup';
export const SIGNEDUP = '/signedup';
export const EMPLOYEES = '/employees';
export const SETTINGS = '/settings';
export const REPORTS = '/reports';
export const USER_SETTINGS = '/usersettings';
export const PROFILE = '/profile';
export const EMPLOYEE = `${EMPLOYEES}/:id`;
export const FORGOT_PASSWORD = '/forgot_password';
export const RENEW_PASSWORD = '/renew_password';

export const ROUTES: Record<string, string> = {
  HOME,
  TOOL,
  LOGIN,
  LOGGED_IN,
  LOGOUT,
  ERROR,
  SIGNUP,
  SIGNEDUP,
  EMPLOYEES,
  USER_SETTINGS,
  PROFILE,
  EMPLOYEE,
  FORGOT_PASSWORD,
  RENEW_PASSWORD,
};

export default ROUTES;
