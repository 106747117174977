import jwtDecode from 'jwt-decode';
import { AuthData, DecodedAuthToken, Urn } from 'types/DecodedAuthToken';

export const parseUrnType = (type: string): Urn['type'] => {
  const mapping: Record<string, Urn['type']> = {
    emp: 'employee',
    organisations: 'organisation',
    sup: 'support_user',
  };

  return mapping[type];
};

export const parseUrn = (urn: string): Urn => {
  if (!urn) throw new Error('argument urn is not present');

  const segments = urn.split(':');
  const type = parseUrnType(segments[2]);
  const id = parseInt(segments[3], 10);

  return {
    type,
    id,
  };
};

export const parseToken = (token: string): AuthData => {
  const decodedToken: DecodedAuthToken = jwtDecode(token);

  return {
    issuer: decodedToken.iss,
    expirationTime: decodedToken.exp,
    notValidBefore: decodedToken.nbf,
    jwtId: decodedToken.jti,
    subject: parseUrn(decodedToken.sub),
    organization: parseUrn(decodedToken.org),
    firstname: decodedToken.firstname,
    lastname: decodedToken.lastname,
    email: decodedToken.email,
    scope: decodedToken.scope,
  };
};
