export const validatePassword = (pass: string): boolean => {
  if (pass.length < 8 && pass.length > 0) return false;
  return true;
};

export const matchPasswords = (repeatPass: string, pass: string): boolean => {
  if (repeatPass === pass || repeatPass.length === 0) return true;
  return false;
};

export default validatePassword;
