import { createSelector } from '@reduxjs/toolkit';
import { getParsedToken } from 'services/auth/token';
import { RootState, EmployeeState } from 'store/types';

import type { User } from 'types';
import entityAdators from './entityAdapter';

const {
  selectById: selectEmployeeById,
  selectAll: selectAllEmployees,
  selectEntities: selectEmployeeEntities,
  selectIds: selectEmployeeIds,
  selectTotal: selectEmployeeTotal,
} = entityAdators.getSelectors();

export const selectSelf = (state: RootState): EmployeeState => state.employees;

export {
  selectEmployeeById,
  selectAllEmployees,
  selectEmployeeEntities,
  selectEmployeeIds,
  selectEmployeeTotal,
};

export const selectCurrentEmployee = createSelector(
  selectSelf,
  (state: EmployeeState): User | undefined => {
    const authData = getParsedToken();
    if (!authData) return undefined;
    if (!['employee', 'support_user'].includes(authData.subject.type))
      return undefined;

    const employeeId = authData.subject.id;
    return selectEmployeeById(state, employeeId);
  }
);
